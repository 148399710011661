import axios from "axios"
import authService from "./service/security/auth.service";
import router from './router';
import store from './store'
import StorageService from './service/StorageService';

window.axios = axios
axios.defaults.withCredentials = false

// Interceptador de requests
axios.interceptors.request.use(
    (config) => {
        if(config.url && 
            (config.url.indexOf('/auth/v1/login') >= 0 
            || config.url.indexOf('/recuperar-senha') >= 0
            || config.url.indexOf('/novo-cadastro') >= 0 
            || config.url.indexOf('/informacoes-exclusao-conta') >= 0 
            || config.url.indexOf('/auth/v1/cooperados') >= 0
            || config.url.indexOf('/chatbot') >= 0 )
            || config.url.indexOf('/auth/v1/termo/uso') >= 0 
            || config.url.indexOf('/auth/v1/termo/politica-privacidade') >= 0 
            || config.url.indexOf('/cooperado/v1/imagem-login/site') >= 0 
            || config.url.indexOf('/cooperado/v1/estabelecimento') >= 0 ) {
            return config;
        }

        const auth = StorageService.getAuth();

        if(auth){
            config.headers.Authorization = `Bearer ${auth.accessToken}`;
        }
        else {
            subscribers = [];
            router.push('/login');
        }
        
        const dadosSensiveis = StorageService.getMostrarDadosSensiveis();
        config.headers.MostrarDadosSensiveis = dadosSensiveis;

        //console.log(config);

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptador de response
let isRefreshing = false;
let subscribers = [];

axios.interceptors.response.use(
    (config) => {
        return config;
    },
    (error) => {
        const {
            config, 
            response: { status, data} 
        } = error;

        if(config.url && config.url.indexOf('/auth/v1/refresh') >= 0) {
            logout();
            return Promise.reject(false);
        }
        
        if(status === 401 && data.message_error 
            && (data.message_error.indexOf("The Token has expired") >= 0 || data.message_error.indexOf("Possui um acesso ativo") >= 0)) {
                const auth = StorageService.getAuth();
                if(!auth || data.message_error.indexOf("Possui um acesso ativo") >= 0) {
                    logout();
                    return Promise.reject(false);
                }
                
                if(!isRefreshing) {
                    isRefreshing = true;
                    
                    authService.refresh(auth.refreshToken)
                        .then(({status, data}) => {                        
                            if(status === 200 || status == 204) {
                                isRefreshing = false;
                                StorageService.setAuth(data);
                                onRefreshed();
                            }

                            subscribers = [];
                        })
                        .catch((err) => {
                            logout();
                        });
                }
                
                const requestSubscribers = new Promise(resolve => {
                    subscribeTokenRefresh(() => {
                        resolve(axios(config));
                    });
                });

                return requestSubscribers;
        }
        return Promise.reject(error);
    }
);

function logout() {
    isRefreshing = false;
    subscribers = [];
    store.dispatch('auth/logout');
    router.go();
}

function subscribeTokenRefresh(cb) {
    subscribers.push(cb);
}

function onRefreshed() {
    subscribers.map(cb => cb());
}