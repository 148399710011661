<template>
	<div class="layout-footer flex align-items-center p-4 shadow-2">
		<!-- <img id="footer-logo" :src="'layout/images/footer-' + (layoutMode === 'light' ? 'ultima' : 'ultima-dark') + '.svg'" alt="ultima-footer-logo"> -->
		<img id="footer-logo" :src="'layout/images/logo_coop_integrada.png'" alt="integrada-footer-logo"
			style="width: 170px;">
		<a class="p-button-rounded p-button-text p-button-plain" :class="{ 'ml-auto mr-2': !isRTL, 'ml-2 mr-auto': isRTL }"
			href="https://www.facebook.com/cooperativaintegrada" target="_blank">
			<i class="pi pi-facebook fs-large p-button-rounded p-button-text p-button-plain"
				:class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"></i>
		</a>
		<a class="p-button-rounded p-button-text p-button-plain"
			href="https://www.linkedin.com/company/cooperativaintegrada/" target="_blank">
			<i class="pi pi-linkedin fs-large p-button-rounded p-button-text p-button-plain"
				:class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"></i>
		</a>
		<a class="p-button-rounded p-button-text p-button-plain mr-7"
			href="https://www.youtube.com/channel/UC66dE33MWxuPNAxcdEda-Mg" target="_blank">
			<i class="pi pi-youtube fs-large p-button-rounded p-button-text p-button-plain"
				:class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"></i>
		</a>
	</div>

	<Iris v-if="canShow" />
</template>

<script>
import Iris from './components/widgets/chatbot/Iris.vue';

export default {
	name: "AppFooter",
	props: {
		layoutMode: {
			type: String,
			default: "light"
		}
	},
	computed: {
		isRTL() {
			return this.$appState.RTL;
		},

		canShow() {
			const notPermitedHashes = ['login', 'validar-assinatura'];
			const hash = document.location.hash;
			if (notPermitedHashes.some(h => hash.includes(h))) {
				return false;
			}
			return this.$store.state.auth.status.loggedIn;
		},
	},
	components: { Iris }
};
</script>
