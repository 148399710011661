import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/cooperados`;

class CooperadoService {
    carregarCooperados(pagina, qtdRegistro) {
        return axios.get(`${ API_BASE_URL }${RESOURCE_V1}`, {
            params: {
                page: pagina,
                size: qtdRegistro
            }
        });
    }

    ativar(matricula) {
        return axios.put(`${ API_BASE_URL}${RESOURCE_V1}/${matricula}/ativo`);
    }

    inativar(matricula) {
        return axios.put(`${ API_BASE_URL}${RESOURCE_V1}/${matricula}/inativo`);
    }

    buscarCooperado(matricula) {
        return axios.get(`${ API_BASE_URL }${RESOURCE_V1}/${matricula}`);
    }

    alterar(cooperado) {
        return axios.put(`${API_BASE_URL}${RESOURCE_V1}`, cooperado);
    }

    buscarHistoricoAcessos(matricula, pagina, qtdRegistro) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/${matricula}/historico-acessos`, {
            params: {
                page: pagina,
                size: qtdRegistro
            }
        });
    }

    baixarTermoResponsabilidade(matricula) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/${matricula}/termo`);
    }

    alterarAceiteTermos(cooperado) {
        return axios.put(`${API_BASE_URL}${RESOURCE_V1}/aceite-termos`, cooperado);
    }

    buscarCooperados(filtro, params) {
        return axios.post(`${API_BASE_URL}${RESOURCE_V1}/busca`, filtro, {params});
    }

    buscarDadoPessoal(matricula) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/${matricula}/dado-pessoal`);
    }

    alterarDadoPessoal(cooperado) {
        return axios.put(`${API_BASE_URL}${RESOURCE_V1}/dado-pessoal`, cooperado);
    }

    salvarNovoCadastro(cooperado) {
        return axios.post(`${API_BASE_URL}${RESOURCE_V1}`, cooperado);
    }

    imagemFotoPerfil(matricula){
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/recuperar-link-foto-perfil/${matricula}`);
    }

    salvarFotoPerfil(dtoImagem){
        return axios.post(`${API_BASE_URL}${RESOURCE_V1}/foto-perfil/`, dtoImagem);
    }

    excluirFotoPerfil(dtoImagem){
        return axios.delete(`${API_BASE_URL}${RESOURCE_V1}/foto-perfil/`, dtoImagem);
    }
    
}

export default new CooperadoService();
