<template>
    <div class="layout-inline-menu" :class="{'layout-inline-menu-active': active}">
        <div class="" style="display: flex;">
            <div class="flex" style="width: 85%;">
                <a class="layout-inline-menu-action flex flex-row align-items-center" :class="menuMode === 'horizontal' ? 'p-3 lg:p-1 py-3' : 'p-3'" :style="{'min-width': menuMode === 'horizontal' ? '165px' : 0} " @click="onClick($event)">
                    <img v-bind:src="imagemFotoPerfil" alt="avatar" style="width: 32px; height: 32px;">
                    <span class="flex flex-column" :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                        <span class="font-bold">{{cooperado}}</span>
                        <small>Cooperado</small>
                    </span>
                    
                    <i class="layout-inline-menu-icon pi pi-angle-down" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}"></i>
                </a>

                
            </div>
            <div class="flex" style="width: 15%;">
                <a class="layout-inline-menu-action flex flex-row align-items-center" 
                    :class="menuMode === 'horizontal' ? 'p-3 lg:p-1 py-3' : 'p-3'" 
                    :style="{'min-width': menuMode === 'horizontal' ? '165px' : 0} " 
                    @click="exibirDadosSensiveis()">
                    
                    <i v-if="mostrarDadosSensiveis" class="layout-inline-menu-icon pi pi-eye" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" style="font-size: 1rem"></i>
                    <i v-else class="layout-inline-menu-icon pi pi-eye-slash" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" style="font-size: 1rem"></i>
                    
                </a>
            </div>
        </div>

    <!-- <a class="layout-inline-menu-action flex flex-row align-items-center" 
       :class="menuMode === 'horizontal' ? 'p-3 lg:p-1 py-3' : 'p-3'" 
       :style="{'min-width': menuMode === 'horizontal' ? '165px' : 0} " 
       @click="exibirDadosSensiveis()">
       <span class="flex flex-column" :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
         <span v-if="mostrarDadosSensiveis">Ocultar dados sensíveis</span>
         <span v-else>Mostrar dados sensíveis</span> 
       </span>
       <i v-if="mostrarDadosSensiveis" class="layout-inline-menu-icon pi pi-eye" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" style="font-size: 1rem"></i>
       <i v-else class="layout-inline-menu-icon pi pi-eye-slash" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" style="font-size: 1rem"></i>
       
    </a>
    <a class="layout-inline-menu-action flex flex-row align-items-center" :class="menuMode === 'horizontal' ? 'p-3 lg:p-1 py-3' : 'p-3'" :style="{'min-width': menuMode === 'horizontal' ? '165px' : 0} " @click="onClick($event)">
        <img src="layout/images/user.png" alt="avatar" style="width: 32px; height: 32px;">
        <span class="flex flex-column" :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
            <span class="font-bold">{{cooperado}}</span>
            <small>Cooperado</small>
        </span>
        
        <i class="layout-inline-menu-icon pi pi-angle-down" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}"></i>
    </a> -->
    <span class="layout-inline-menu-tooltip p-tooltip">
		<span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
		<span class="layout-inline-menu-tooltip-text p-tooltip-text">Amy Elsner</span>
	</span>
    
    <transition :name="menuMode === 'horizontal' && !isMobile() ? 'layout-inline-menu-action-panel-horizontal' : 'layout-inline-menu-action-panel'">
        <ul v-show="active" :class="['layout-inline-menu-action-panel', { 'layout-inline-menu-action-panel-horizontal' : menuMode === 'horizontal' && !isMobile()}]">
            <li class="layout-inline-menu-action-item">
                <a class="flex flex-row align-items-center" @click="$emit('abrirModalAlterarSenha')">
                    <i class="pi pi-lock pi-fw"></i>
                    <span>Alterar senha</span>
                </a>
                <span class="layout-inline-menu-tooltip p-tooltip">
					<span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
					<span class="layout-inline-menu-tooltip-text p-tooltip-text">Terms of Usage</span>
				</span>
            </li>

            <li class="layout-inline-menu-action-item">
                <a class="flex flex-row align-items-center" @click.prevent="dadosPessoais" >
                    <i class="pi pi-fw pi-user"></i>
                    <span>Dados Pessoais</span>
                </a>
                <span class="layout-inline-menu-tooltip p-tooltip">
					<span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
					<span class="layout-inline-menu-tooltip-text p-tooltip-text">Support</span>
				</span>
            </li>

            <!-- <li class="layout-inline-menu-action-item">
                <a class="flex flex-row align-items-center">
                    <i class="pi pi-comments pi-fw"></i>
                    <span>Suporte</span>
                </a>
                <span class="layout-inline-menu-tooltip p-tooltip">
					<span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
					<span class="layout-inline-menu-tooltip-text p-tooltip-text">Support</span>
				</span>
            </li> -->
            <li class="layout-inline-menu-action-item">
                <a class="flex flex-row align-items-center" @click.prevent="logOut">
                    <i class="pi pi-power-off pi-fw"></i>
                    <span>Sair</span>
                </a>
                <span class="layout-inline-menu-tooltip p-tooltip">
					<span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
					<span class="layout-inline-menu-tooltip-text p-tooltip-text">Logout</span>
				</span>
            </li>
    </ul>
    </transition>
</div>

</template>

<script>
    import StorageService from './service/StorageService';
    import CooperadoService from "./service/CooperadoService";
	export default {
        name: "AppInlineMenu",
        data() {
			return {
				matricula: null,
				cooperado: null,
                //mostrarDadosSensiveis:false,
                mostrarDadosSensiveis:true,
                imagemFotoPerfil:null,
            }
        },
        props: {
            active: {
                type: Boolean,
                default: false
            },
            menuMode: String,
            inlineMenuKey: String
        },
		methods: {
			onClick(event) {
                this.$emit('change-inlinemenu', !this.active, this.inlineMenuKey);
				event.preventDefault();
			},
            isMobile() {
                return window.innerWidth <= 991;
            },
            logOut() {
				this.$store.dispatch('auth/logout');
                window.location.href = '/';
			},
            dadosPessoais() {
                window.location.href = '/#/dados-pessoais';
			},
            exibirDadosSensiveis(){
				
				let dadosSensiveisv = StorageService.getMostrarDadosSensiveis();
                
                console.log("DadosSensiveis:", dadosSensiveisv);

				if(dadosSensiveisv){
					StorageService.setMostrarDadosSensiveis("false");
					this.mostrarDadosSensiveis = false;
				}
				else{
					StorageService.setMostrarDadosSensiveis("true");
					this.mostrarDadosSensiveis = true;
				}

				//location.reload(true); //Verificar se tem uma outra forma mais elegante
				this.$router.go(this.$router.currentRoute);

			},
            mostrarFotoPerfil(){

                if(this.currentUser == null)
                    return;

                let matricula = 0;
				if(this.currentUser != null && this.currentUser.matricula != null){
					matricula = this.currentUser.matricula;
				}

                CooperadoService.imagemFotoPerfil(matricula)
                    .then(response => {
                        this.imagemFotoPerfil = response.data;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }

		},
        computed: {
            isRTL() {
				return this.$appState.RTL;
			},
			currentUser() {
				return this.$store.state.auth.user;
			}
            
        },
		mounted() {
			if (this.currentUser) {
				this.matricula = this.currentUser.matricula;   
				this.cooperado = this.currentUser.cooperado.nome;  
			}
            this.mostrarDadosSensiveis = StorageService.getMostrarDadosSensiveis();
            this.mostrarFotoPerfil();
		},
        
        
	}
</script>

<style scoped>

</style>