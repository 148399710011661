import axios from "axios";
import PortalCooperadoUtils from "../utilities/PortalCooperadoUtils";

const CONTAS_PAGAR_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/`;

class ValidarAssinaturaService {
  async validar(codigoVerificador, crc) {
    const response = await fetch(
      CONTAS_PAGAR_API_BASE_URL + RESOURCE_V1 + `validar-envelope/${codigoVerificador}/${crc}`
    );
    const data = await response.json();
    return data;
  }
  async downloadDocumento(codigoVerificador, crc) {
    const response = await fetch(
      CONTAS_PAGAR_API_BASE_URL + RESOURCE_V1 + `validar-envelope-download/${codigoVerificador}/${crc}`,
      {
        responseType: "blob",
      }
    );
    const blob = await response.blob();
    return blob;
  }
}

export default new ValidarAssinaturaService();
