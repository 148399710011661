<template>
    <Toast />


    <Dialog v-model:visible="pdfContent" :style="{ width: '90%', height: '90%' }" :modal="true"
        @hide="fecharVisualizacao">

        <template #header>
            <div class="flex align-items-center">
                <i class="pi pi-unlock mr-1" style="font-size: 1.4rem"></i>
                <h3 style="margin:0px 5px;">Documento</h3>
            </div>
        </template>
        <iframe :src="pdfContent" width="100%" height="850px"></iframe>
    </Dialog>


    <div id="ImagemFundo" class="pages-body flex flex-column"
        v-bind:style="{ 'background-image': 'url(data:image/png;base64,' + img + ')', 'background-size': 'cover' }">
        <div class="align-self-center mt-auto mb-auto">
            <div class="pages-panel card flex flex-column">
                <h5 class="pages-header px-3 py-1 text-center">Conferência de Autenticidade de Documentos Integrada</h5>
                <div class="pages-detail mb-6 px-6 text-center">Informe os dados para verificar a autenticidade do
                    documento.</div>

                <div class="field">
                    <span class="p-float-label">
                        <InputText type="text" id="codigoVerificador" v-model="codigoVerificador" class="w-full" />
                        <label for="codigoVerificador">Código Verificador</label>
                    </span>
                </div>
                <div class="field">
                    <span class="p-float-label">
                        <InputText type="text" id="codigoCRC" v-model="codigoCRC" class="w-full" />
                        <label for="codigoCRC">Código CRC</label>
                    </span>
                </div>

                <div class="field mt-3" v-if="nodeEnv">
                    <g-recaptcha :dataSitekey="siteKey" dataLanguage="pt-BR" :dataCallback="callback"
                        v-if="nodeEnv == 'production'" />
                </div>

                <div v-if="error" class="alert alert-danger">
                    <p class="p-error">{{ error }}</p>
                </div>

                <div v-if="message" class="alert alert-success">
                    <p>{{ message }}</p>
                </div>

                <Button :loading="loading" @click="validarDocumento" class="submit-button">Consultar</Button>

                <p v-if="assinantes.length > 0" style="margin-top:16px">Para visualizar o documento, clique
                    <a @click="visualizarDocumento" class="text-primary" style="cursor:pointer">aqui.</a>
                </p>


                <DataTable :value="assinantes" v-if="assinantes.length > 0" class="p-datatable-gridlines"
                    :rowHover="true" :loading="loading" responsiveLayout="scroll">
                    <template #loading>
                        Carregando dados. Por favor, aguarde!
                    </template>
                    <Column field="identificador" header="Identificador" style="width: 8rem;" />
                    <Column field="dataAssinatura" header="Data da Assinatura" style="width: 22rem;" />
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import gRecaptcha from '../utilities/Recaptcha.vue';
import service from '../service/ValidarAssinaturaService';
import ImagemFundoService from '../service/ImagemFundo';

const VALIDAR_ASSINATURA_RENDER_KEY = '@validar-assinatura/first-render';

export default {
    name: 'validarPedido',
    components: {
        gRecaptcha,
    },
    data() {
        return {
            codigoVerificador: '',
            codigoCRC: '',
            tokenCaptcha: '',
            code: '',
            firstRender: window.localStorage.getItem(VALIDAR_ASSINATURA_RENDER_KEY),
            message: '',
            error: '',
            assinantes: [],
            pdfContent: null,
            loading: false,
            siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
            nodeEnv: process.env.NODE_ENV,
            img: ''
        };
    },
    mounted() {
        this.reload();
        this.recuperarImagemFundo();
    },
    methods: {
        reload() {
            if (!this.firstRender) {
                window.localStorage.setItem(VALIDAR_ASSINATURA_RENDER_KEY, 'true');
                window.location.reload();
                return;
            }

            window.localStorage.removeItem(VALIDAR_ASSINATURA_RENDER_KEY);
        },
        isRecaptchaValid() {
            if (this.tokenCaptcha === '' && this.nodeEnv === 'production') {
                alert('Por favor, valide o CAPTCHA');
                return false;
            }
            return true;
        },
        async validarDocumento() {
            try {
                this.error = '';
                this.message = '';
                this.assinantes = [];
                this.loading = true;

                if (this.codigoVerificador === '' || this.codigoCRC === '') {
                    const errorMessage = 'Por favor, preencha os campos corretamente';
                    this.abrirToast('error', errorMessage, errorMessage, 3000);
                    this.error = error;
                    return;
                }

                if (!this.isRecaptchaValid()) {
                    const errorMessage = 'Por favor, valide o CAPTCHA';
                    this.error = errorMessage;
                    this.abrirToast('error', 'CAPTCHA Inválido', errorMessage, '', 3000);
                    return;
                }

                const data = await service.validar(
                    this.codigoVerificador,
                    this.codigoCRC
                );


                if (!data || !data.code) {
                    this.error = 'Erro ao validar o documento';
                    this.abrirToast('error', this.error, '', 3000);
                    return;
                }

                if (data.code === 200) {
                    this.assinantes = data.assinantes.map(assinante => ({
                        identificador: assinante.identificador,
                        dataAssinatura: assinante.dataHoraAssinatura ? new Date(assinante.dataHoraAssinatura).toLocaleString('pt-BR', {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            timeZoneName: 'short'
                        }) : '-'
                    }));
                    this.message = data.message;
                    this.abrirToast('success', this.message, '', 10000);
                } else {
                    this.abrirToast('error', data.message, data.message, 3000);
                    this.error = data.message;
                }
            } finally {
                this.loading = false;
            }
        },
        fecharVisualizacao() {
            this.pdfContent = null;
        },
        visualizarDocumento() {
            if (!this.isRecaptchaValid()) {
                return;
            }
            service.downloadDocumento(this.codigoVerificador, this.codigoCRC)
                .then(response => {
                    if (typeof response === 'string') {
                        this.abrirToast('error', response, '', 3000);
                        return;
                    }
                    this.pdfContent = URL.createObjectURL(response);
                })
                .catch(error => {
                    console.error("Erro ao tentar visualizar o PDF:", error);
                    this.abrirToast('error', 'Erro ao tentar visualizar o PDF', '', 3000);
                });
        },
        callback(tokenCaptcha) {
            this.tokenCaptcha = tokenCaptcha;
        },
        abrirToast(tipo, titulo, descricao, tempo) {
            this.$toast.add({
                severity: tipo,
                summary: titulo,
                detail: descricao,
                life: tempo
            });
        },
        async recuperarImagemFundo() {
            ImagemFundoService.recuperarImagemFundo("site")
                .then(
                    (response) => {
                        this.img = response.data.base64;
                    },
                    (error) => {
                        console.log("Erro ao recuperar a imagem de fundo: ", error);
                    }
                );
        }
    },
};
</script>

<style scoped>
.pages-body {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pages-panel {
    opacity: 0.9;
    max-width: 500px;
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.pages-header {
    background: rgb(76, 146, 83);
    color: white;
    padding: 16px;
    border-radius: 10px 10px 0 0;
}

.pages-detail {
    margin-bottom: 1rem;
    text-align: center;
    color: #333;
}

.field {
    margin-bottom: 16px;
}

.submit-button {
    padding: 8px;
    margin-top: 16px;
    align-self: center;
}
</style>