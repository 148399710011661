import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/mensagem`;

class MensagemService {
    getNotificacoes(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/novas/" + matricula);
    }

    getMensagemPorId(mensagemId) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/id/" + mensagemId);
    }

    getMensagensPorPeriodo(matricula, dataInicio, dataFim) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + `/todas/${ matricula }/${ dataInicio }/${ dataFim }`);
    }
}

export default new MensagemService();