import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/favoritos`;

class FavoritoService {
    adicionar(matricula, codigoFuncionalidade) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/adicionar/" + matricula + "/" + codigoFuncionalidade);
    }

    remover(idFavorito) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/remover/" + idFavorito);
    }

    listar(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/" + matricula);
    }
}

export default new FavoritoService();