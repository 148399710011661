import axios from 'axios';
import StorageService from '../StorageService';
import PortalCooperadoUtils from '../../utilities/PortalCooperadoUtils';
import CooperadoCidadePrevisaoTempoService from '../CooperadoCidadePrevisaoTempoService';

const API_URL = PortalCooperadoUtils.apiCooperadoAuth;
const RESOURCE_V1 = `/v1`;

class AuthService {
	
    login(user) {
        const body = {
            username: user.username,
            password: user.password
        }

        const headers = {
            'content-type': 'application/json'
        }

        return new Promise((resolve, reject) => {
            axios.post(`${API_URL}${RESOURCE_V1}/login`, body, { headers })
                .then(response => {
                    return resolve(response);
                })
                .catch(error => {
                    return reject(error);
                });
        });
	}

    logout() {
        StorageService.setAuth(null);
        StorageService.setUser(null);
        StorageService.setAdmin(null);
    }
    
	getCooperados(nomeOuMatricula) {
		return axios.get(API_URL + RESOURCE_V1 + '/options/todos-cooperados/' + nomeOuMatricula);
	}

    refresh(refreshToken) {
        const body = {
            currentToken: 'Bearer ' + refreshToken
        }

        const headers = {
            'content-type': 'application/json'
        }

        return new Promise((resolve, reject) => {
            axios.post(API_URL + RESOURCE_V1 + '/refresh', body, { headers })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    alterarSenha(cooperado) {
        return axios.put(`${API_URL}${RESOURCE_V1}/cooperados/senha`, cooperado);
    }

    cadastrarCooperado(cooperado) {
        return axios.post(`${API_URL}${RESOURCE_V1}/cooperados`, cooperado);
    }

    async buscarCooperadoCidadesPrevisaoTempo(matricula) {
        let cooperadoCidadesPrevisaoTempo = [
            {
                'codigo': 'Londrina-PR',
                'id': 28,
                'nome': 'Londrina PR'
            }
        ]
        const {data} = await CooperadoCidadePrevisaoTempoService.buscarCooperadoCidadesPrevisaoTempo(matricula);
        if (data) {
            cooperadoCidadesPrevisaoTempo = data
        }

        return cooperadoCidadesPrevisaoTempo;
    }

    validarCooperado(matricula) {
        return axios.get(`${API_URL}${RESOURCE_V1}/cooperados/${matricula}/validar`);
    }
}

export default new AuthService();
