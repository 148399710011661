<template>
	<Toast />
	<div class="layout-topbar shadow-4">
		<div class="layout-topbar-left">
			<router-link class="layout-topbar-logo" to="/">
				<img id="logo" src="layout/images/logo_portal_branca.png" alt="ultima-layout" style="height: 2.25rem">
			</router-link>

			<a class="layout-menu-button shadow-6 p-ripple" @click="onMenuButtonClick($event)" v-ripple>
				<i class="pi pi-chevron-right"></i>
			</a>

			<a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
				<i class="pi pi-ellipsis-v fs-large"></i>
			</a>
		</div>

		<div class="layout-topbar-right" :class="{ 'layout-topbar-mobile-active': mobileTopbarActive }">
			<div class="layout-topbar-actions-left col-12 sm:col-12 md:col-12 lg:col-4">
				<div v-if="!isAdministrador()" class="p-inputgroup">
					<span class="p-inputgroup-addon">
						<i class="pi pi-search fs-large"></i>
					</span>
					<AutoComplete placeholder="Pesquisar menu..." :minLength="minLengthFilter" v-model="funcionalidadeSelecionada"
						field="nome" :suggestions="funcionalidadesFiltrada" @complete="buscarFuncionalidades($event)"
						@item-select="selecionarFuncionalidade" />
				</div>

				<div v-if="isAdministrador()" class="p-inputgroup">
					<span class="p-inputgroup-addon">
						<i class="pi pi-users fs-large"></i>
					</span>
					<AlterarCooperado placeholder="Alterar cooperado" @mensagemErro="erroColaborador"
						@sucesso="sucessoCooperadoSelecionado" />
				</div>
			</div>

			<div class="layout-topbar-actions-right">
				<ul class="layout-topbar-items">
					<!-- <li class="layout-topbar-item notifications">
						<a class="layout-topbar-action rounded-circle p-ripple" @click="onTopbarItemClick($event, 'notifications')" v-ripple>
							<span v-if="notificacoes.length > 0" class="p-overlay-badge" v-badge.warning>
								<i class="pi pi-bell fs-large"></i>
							</span>
							
							<span v-if="notificacoes.length <= 0" class="p-overlay-badge">
								<i class="pi pi-bell fs-large"></i>
							</span>
						</a>

						<transition name="layout-topbar-action-panel">
							<ul class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'notifications'">
								<li class="mb-3">
									<span class="px-3 fs-small">Você tem <strong>{{ totalNotificacoes }}</strong> notificações</span>
								</li>

								<li class="layout-topbar-action-item" v-for="item in notificacoes" :key="item.id" @click="abrirMensagem(item.id)">
									<div class="flex flex-row align-items-center">
										<img :src="'data:image/jpg;base64,' + item.imagemProfile"/>
										<div class="flex flex-column" :class="{'ml-3': !isRTL, 'mr-3': isRTL}" style="flex-grow: 1;">
											<div class="flex align-items-center justify-content-between mb-1">
												<span class="fs-small font-bold">{{ item.nome }}</span>
												<small>{{ item.dataMensagem }}</small>
											</div>
											<span class="fs-small">{{ item.titulo }}</span>
										</div>
									</div>
								</li>
								
								<li class="mt-3 pl-3 pr-3">
									<Button class="w-full" label="Visualizar todas mensagens" @click="visualizarMensagens"></Button>
								</li>
							</ul>
						</transition>
					</li> -->

					<!-- <li class="layout-topbar-item app">
						<a class="layout-topbar-action rounded-circle p-ripple" @click="onTopbarItemClick($event, 'apps')" v-ripple>
							<i class="pi pi-table fs-large"></i>
						</a>

						<transition name="layout-topbar-action-panel">
								<div class="grid grid-nogutter layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'apps'">	
									<div class="layout-topbar-action-item col-4">
										<a class="flex align-items-center flex-column text-color p-ripple" v-ripple>
											<i class="pi pi-image action indigo-bgcolor white-color"></i>
											<span>Products</span>
										</a>
									</div>
									<div class="layout-topbar-action-item col-4">
										<a class="flex align-items-center flex-column text-color p-ripple" v-ripple>
											<i class="pi pi-file-pdf action orange-bgcolor white-color"></i>
											<span>Reports</span>
										</a>
									</div>
									<div class="layout-topbar-action-item col-4">
										<a class="flex align-items-center flex-column text-color p-ripple" v-ripple>
											<i class="pi pi-dollar action teal-bgcolor white-color"></i>
											<span>Balance</span>
										</a>
									</div>
									<div class="layout-topbar-action-item col-4">
										<a class="flex align-items-center flex-column text-color p-ripple" v-ripple>
											<i class="pi pi-cog action pink-bgcolor white-color"></i>
											<span>Settings</span>
										</a>
									</div>
									<div class="layout-topbar-action-item col-4">
										<a class="flex align-items-center flex-column text-color p-ripple" v-ripple>
											<i class="pi pi-key action bluegrey-bgcolor white-color"></i>
											<span>Credentials</span>
										</a>
									</div>
									<div class="layout-topbar-action-item col-4">
										<a class="flex align-items-center justify-content-center flex-column text-color p-ripple" v-ripple>
											<i class="pi pi-sitemap action cyan-bgcolor white-color"></i>
											<span>Sitemap</span>
										</a>
									</div>
								</div>
						</transition>
					</li> -->
					<!-- {{ mostrarDadosSensiveis }} -->
					<li class="layout-topbar-item" v-if="false">
						<a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-ripple"
							@click="dadosSensiveis()">
							<i v-if="mostrarDadosSensiveis" class="pi pi-eye" style="font-size: 1rem"></i>
							<i v-if="!mostrarDadosSensiveis" class="pi pi-eye-slash" style="font-size: 1rem"></i>

						</a>
					</li>

					<li class="layout-topbar-item">
						<a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-ripple"
							@click="onTopbarItemClick($event, 'profile')" v-ripple>
							<img :src="imagemFotoPerfil" alt="avatar" style="width: 32px; height: 32px;">
						</a>

						<transition name="layout-topbar-action-panel">
							<ul class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'profile'">
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple
										@click="$emit('abrirModalAlterarSenha')">
										<i class="pi pi-lock" :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"></i>
										<span>Alterar senha</span>
									</a>
								</li>

								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple @click.prevent="dadosPessoais">
										<i class="pi pi-fw pi-user" :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"></i>
										<span>Dados Pessoais</span>
									</a>
								</li>

								<!-- <li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple>
										<i class="pi pi-comments" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Suporte</span>
									</a>
								</li> -->
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple @click.prevent="logOut">
										<i class="pi pi-power-off" :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"></i>
										<span>Sair</span>
									</a>
								</li>
							</ul>
						</transition>
					</li>

					<!-- <li class="layout-topbar-item">
						<a class="layout-topbar-action rounded-circle p-ripple" @click="onRightPanelButtonClick($event)" v-ripple>
							<i class="pi fs-large" :class="{'pi-arrow-left': !isRTL, 'pi-arrow-right': isRTL}"></i>
						</a>
					</li> -->
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import FuncionalidadeService from './service/FuncionalidadeService';
import MensagemService from './service/MensagemService';
import Formatacao from './utilities/Formatacao';
import AlterarCooperado from './components/AlterarCooperado.vue';
import StorageService from './service/StorageService';
import path from 'path';
import CooperadoService from "./service/CooperadoService";

export default {
	components: { AlterarCooperado },
	emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'abrirModalAlterarSenha'],
	data() {
		return {
			funcionalidades: [],
			funcionalidadesFiltrada: [],
			funcionalidadeSelecionada: null,
			minLengthFilter: 3,
			matricula: null,
			cooperado: null,

			totalNotificacoes: 0,
			notificacoes: [],
			mostrarDadosSensiveis: StorageService.getMostrarDadosSensiveis(),
			imagemFotoPerfil: null,
		};
	},
	props: {
		horizontal: {
			type: Boolean,
			default: false
		},
		topbarMenuActive: {
			type: Boolean,
			default: false
		},
		activeTopbarItem: String,
		mobileTopbarActive: Boolean
	},
	methods: {
		onMenuButtonClick(event) {
			this.$emit('menubutton-click', event);
		},
		onTopbarMenuButtonClick(event) {
			this.$emit('topbar-menubutton-click', event);
		},
		onTopbarItemClick(event, item) {
			this.$emit('topbaritem-click', { originalEvent: event, item: item });
		},
		onTopbarMobileButtonClick(event) {
			this.$emit('topbar-mobileactive', event);
		},
		onRightPanelButtonClick(event) {
			this.$emit('rightpanel-button-click', event);
		},
		dadosPessoais() {
			window.location.href = '/#/dados-pessoais';
		},
		logOut() {
			this.$store.dispatch('auth/logout');
			window.location.href = '/';
		},

		async getFuncionalidades() {
			const { data } = await FuncionalidadeService.listar();

			let funcionalidades = [];

			if (data) {
				funcionalidades = data.filter((item) => {
					return item.ativo && !(item.codigo == 'login' || item.codigo == 'capital_social' ||
						item.codigo == 'mensagens' || item.codigo == 'previsao_tempo');
				});
			}

			return funcionalidades;
		},

		async buscarFuncionalidades(event) {
			if (this.funcionalidades.length <= 0) {
				this.funcionalidades = await this.getFuncionalidades();
			}

			if (!event.query.trim().length) {
				this.funcionalidadesFiltrada = [...this.funcionalidades];
			}
			else {
				this.funcionalidadesFiltrada = this.funcionalidades.filter((item) => {
					return item.nome.toLowerCase().startsWith(event.query.toLowerCase());
				});
			}
		},

		selecionarFuncionalidade() {
			if (this.funcionalidadeSelecionada) {
				const url = this.funcionalidadeSelecionada.urlVue;
				this.$router.push(`/${url}`);

				this.funcionalidadeSelecionada = null;
			}
		},

		carregarNotificacoes() {
			MensagemService.getNotificacoes(this.currentUser.matricula)
				.then(({ data }) => {
					if (data) {
						const hoje = new Date();

						this.totalNotificacoes = data.totalMensagens,
							this.notificacoes = data.mensagem.map(item => {
								item.dataMensagem = Formatacao.duracaoEntreDatas(hoje, item.dataMensagem);
								return item;
							});
					}
					else {
						this.totalNotificacoes = 0;
						this.notificacoes = [];
					}
				})
				.catch(error => {
					this.totalNotificacoes = 0;
					this.notificacoes = [];
				});
		},

		abrirMensagem(mensagemId) {
			this.$router.push(`/mensagem/${mensagemId}`);
			this.carregarNotificacoes();
		},

		visualizarMensagens() {
			this.$router.push(`/mensagens`);
		},

		sucessoCooperadoSelecionado(data) {
			this.$router.go();
		},

		erroColaborador(error) {
			this.$toast.add({
				severity: 'error',
				summary: 'Falha',
				detail: error,
				life: 3000
			});
		},

		isAdministrador() {
			return StorageService.getAdmin() !== null;
		},

		dadosSensiveis() {

			const dadosSensiveis = StorageService.getMostrarDadosSensiveis();

			if (dadosSensiveis == "true") {
				StorageService.setMostrarDadosSensiveis("false");
				this.mostrarDadosSensiveis = false;
			}
			else {
				StorageService.setMostrarDadosSensiveis("true");
				this.mostrarDadosSensiveis = true;
			}

			//location.reload(true); //Verificar se tem uma outra forma mais elegante
			this.$router.go(this.$router.currentRoute);

		},
		mostrarFotoPerfil() {

			if (this.currentUser == null)
				return;

			let matricula = 0;
			if (this.currentUser != null && this.currentUser.matricula != null) {
				matricula = this.currentUser.matricula;
			}

			CooperadoService.imagemFotoPerfil(matricula)
				.then(response => {
					this.imagemFotoPerfil = response.data;
				})
				.catch(error => {
					console.error(error);
				});
		}

	},
	computed: {
		topbarItemsClass() {
			return ['topbar-items animated fadeInDown', {
				'topbar-items-visible': this.topbarMenuActive
			}];
		},
		isRTL() {
			return this.$appState.RTL;
		},
		currentUser() {
			return this.$store.state.auth.user;
		}
	},
	mounted() {
		let path = window.location.href;

		if (!this.currentUser) {
			if (!path.includes('recuperar-senha') && !path.includes('chatbot') && !path.includes('validar-assinatura')) {
				this.$router.push('/login');
			}

		} else {
			this.matricula = this.currentUser.matricula;
			this.cooperado = this.currentUser.cooperado.nome;
			this.carregarNotificacoes();
		}

		this.mostrarFotoPerfil();
	}
};
</script>
