import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/imagem-login/salvar-imagem`;
const RESOURCE_V1_RECUPERAR = `/v1/imagem-login`;


class ImagemFundo {

    //destino: site ou app
    salvarImagemFundo(imagem){
        return axios.post(`${API_BASE_URL}${RESOURCE_V1}`, imagem);
    }

    recuperarImagemFundo(destino){
        return axios.get(`${API_BASE_URL}${RESOURCE_V1_RECUPERAR}/${destino}`);
    }
  
}

export default new ImagemFundo();
