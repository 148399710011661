import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperadoService
const RESOURCE_V1 = `/v1/cooperado-cidades`;

class CooperadoCidadePrevisaoTempoService {
    buscarCooperadoCidadesPrevisaoTempo(matricula) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/cooperado/${matricula}`);
    }

    salvarCooperadoCidadesPrevisaoTempo(cooperadoCidadePrevisaoTempo) {
        return axios.post(`${API_BASE_URL}${RESOURCE_V1}`, cooperadoCidadePrevisaoTempo);
    }
}

export default new CooperadoCidadePrevisaoTempoService;
