import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = `${PortalCooperadoUtils.apiCooperadoAuth}`;
const RESOURCE_V1 = `v1/recuperar-senha`

class RecuperarSenhaService {
    recuperarSenha(matricula) {
        return axios.get(`${API_BASE_URL}/${RESOURCE_V1}/${matricula}`);
    }

    buscarCooperadoPorToken(token) {
        return axios.get(`${API_BASE_URL}/${RESOURCE_V1}/cooperado/${token}`);
    }

    alterarSenha(senha) {
        return axios.put(`${API_BASE_URL}/${RESOURCE_V1}`, senha);
    }
}

export default new RecuperarSenhaService();
