import AuthService from '../service/security/auth.service';
import StorageService from '../service/StorageService';
import CooperadoService from '../service/CooperadoService';
import UsuarioService from '../service/UsuarioService';
import CooperadoCidadePrevisaoTempoService from '../service/CooperadoCidadePrevisaoTempoService';

const storageUser = StorageService.getUser(); 
const initialState = storageUser
? { status: { loggedIn: true }, user: storageUser }
: { status: { loggedIn: false }, user: null };

async function criarCooperado(data, modoAdm) {
    let user = {
        nome: data.nome,
        email: data.email,
        estabelecimento: data.estabelecimento.nome,
        matricula: data.matricula,
        administrador: modoAdm,
        cooperado: data,
        login: {
            username: data.matricula,
            roles: data.roles,
        }
    };

    user.cooperado.cidadesPrevisaoTempo = await buscarCooperadoCidadesPrevisaoTempo(data.matricula);

    return user;
}

async function buscarCooperadoCidadesPrevisaoTempo(matricula) {
    let cooperadoCidadesPrevisaoTempo = [
        {
            'codigo': 'Londrina-PR',
            'id': 28,
            'nome': 'Londrina PR'
        }
    ]
    const {data} = await CooperadoCidadePrevisaoTempoService.buscarCooperadoCidadesPrevisaoTempo(matricula);
    if (data) {
        cooperadoCidadesPrevisaoTempo = data
    }

    return cooperadoCidadesPrevisaoTempo;
}


function criarUsuario(data) {
    let user = {
        nome: data.usuario.nome,
        email: data.usuario.email,
        estabelecimento: data.usuario.estabelecimento.nome,
        matricula: data.usuario.matricula,
        administrador: data.administrador,
        usuario: data.usuario,
        login: {
            username: data.usuario.loginLdap,
            roles: [
                {
                    nome: data.tipoUsuarioPortal,
                }
            ],
        }
    };

    return user;
}

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            let onlyNumbers = new RegExp('^[0-9]+$');

            return new Promise((resolve, reject) => {
                
                // Resetar todos os caches de login
                AuthService.logout();
                commit('logout');
                
                AuthService.login(user).then(
                ({ data }) => {
                    StorageService.setAuth(data);

                    if (onlyNumbers.test(user.username)) {
                        CooperadoService.buscarCooperado(user.username)
                            .then( async ({data}) => {
                                let user = await criarCooperado(data, false);

                                StorageService.setUser(user);
                                commit('loginSuccess', user);
        
                                return resolve(data);
                            })
                            .catch(error => {
                                commit('loginFailure');
                                return reject(error);
                            });
                    } else {
                        UsuarioService.buscarPorLoginLdap(user.username)
                            .then( ({data}) => {
                                let user = criarUsuario(data);

                                StorageService.setAdmin(user);
        
                                return resolve(data);
                            })
                            .catch(error => {
                                commit('loginFailure');
                                return reject(error);
                            });
                    }
                },
                error => {
                    commit('loginFailure');
                    return reject(error);
                });
            });
        },

        subLoginCooperado({ commit }, obj) {
            return new Promise((resolve, reject) => {
                CooperadoService.buscarCooperado(obj.matriculaCooperado)
                    .then( async ({data}) => {
                        let user = await criarCooperado(data, true);

                        StorageService.setUser(user);
                        commit('loginSuccess', user);

                        return resolve(data);
                    })
                    .catch(error => {
                        commit('loginFailure');
                        return reject(error);
                    });
            });
        },

        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                commit('registerSuccess');
                return Promise.resolve(response.data);
                },
                error => {
                commit('registerFailure');
                return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    }
};
