<template>
    <Dialog
        v-model:visible="visivel"
        :style="{width: '350px'}"
        :modal="true"
        @hide="$emit('fechar')"
    >
        <template #header>
            <div class="flex align-items-center">
                <i class="pi pi-unlock mr-1" style="font-size: 1.4rem"></i>
                <h3 style="margin:0px 5px;">Alterar senha</h3>
            </div>
        </template>
        <div class="grid">
            <div class="col-12">
                <label><strong>Senha atual<span class="ml-1" style="color:red;">*</span></strong></label>
                <InputText type="password"
                    v-model="alterarSenha.senhaAtual"
                    class="w-full mt-2"
                    :class="{'p-invalid': senhaAtualIsInvalid}"
                    @change="senhaAtualIsInvalid = false"
                ></InputText>
            </div>
            <div class="col-12 mt-2">
                <label><strong>Nova senha<span class="ml-1" style="color:red;">*</span></strong></label>
                <InputText type="password"
                    v-model="alterarSenha.senhaNova"
                    class="w-full mt-2"
                    :class="{'p-invalid': novaSenhaIsInvalid}"
                    @change="novaSenhaIsInvalid = false"
                ></InputText>
            </div>
            <div class="col-12 mt-2">
                <label><strong>Confirmar nova senha<span class="ml-1" style="color:red;">*</span></strong></label>
                <InputText type="password"
                    v-model="confirmarNovaSenha"
                    class="w-full mt-2"
                    :class="{'p-invalid': confirmarNovaSenhaIsInvalid}"
                    @click ="confirmarNovaSenhaIsInvalid = false"
                ></InputText>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="$emit('fechar')" class="p-button-text p-button-danger"/>
            <Button label="Alterar" icon="pi pi-check" @click="salvar()" class="p-button-text " autofocus />
        </template>
    </Dialog>
</template>

<script>
    import AuthService from '../../service/security/auth.service';

    export default {
        emits: ['fechar'],
        props: {
            visivel: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                alterarSenha: {},
                confirmarNovaSenha: null,

                senhaAtualIsInvalid: false,
                novaSenhaIsInvalid: false,
                confirmarNovaSenhaIsInvalid: false
            }
        },
        methods: {
            validarCampos() {
                let isValid = true;
                const toast = {
                    severity:'warn', 
                    summary: 'Alerta', 
                    detail:'Alguns campos obrigatórios não estão preenchidos!', 
                    life: 5000
                }

                if(!this.alterarSenha.senhaAtual) {
                    this.senhaAtualIsInvalid = true;
                    isValid = false;
                }

                if(!this.alterarSenha.senhaNova) {
                    this.novaSenhaIsInvalid = true;
                    isValid = false;
                }

                if(!this.confirmarNovaSenha) {
                    this.confirmarNovaSenhaIsInvalid = true;
                    isValid = false;
                }
                else if(this.alterarSenha.senhaNova !== this.confirmarNovaSenha) {
                    toast.detail = `A senha informada no campo "Confirmar nova senha" está diferente do campo "Nova senha".`
                    this.confirmarNovaSenhaIsInvalid = true;
                    isValid = false;
                }

                if(!isValid) {
                    this.$toast.add(toast);
                }

                return isValid;
            },

            salvar() {
                if(!this.validarCampos()) return;

                this.alterarSenha.matricula = this.$store.state.auth.user.matricula;

                AuthService.alterarSenha(this.alterarSenha)
                    .then(({ data }) => {
                        this.$toast.add({
                            severity:'success', 
                            summary: 'Senha alterada com sucesso!',
                            life: 5000
                        });
                        
                        this.alterarSenha = {};
                        this.confirmarNovaSenha = null;
                        this.$emit('fechar');
                    })
                    .catch(error => {
                        this.$toast.add({
                            severity:'error', 
                            summary: `Erro ao alterar senha! Motivo: ${error.response.data.message_error}`,
                            life: 5000
                        });
                    });
            }
        }
    }
</script>
