<template>
    <Dialog v-model:visible="visivelProp"
            :style="{width: '350px'}"
            :modal="true"
            @hide="$emit('fechar')">

        <template #header>
            <div class="flex align-items-center">
                <i class="pi pi-unlock mr-1" style="font-size: 1.4rem"></i>
                <h3 style="margin:0px 5px;">Recuperar Senha</h3>
            </div>
        </template>
        <div class="grid">
            <div class="col-12">
                <label><strong>Matrícula<span class="ml-1" style="color:red;">*</span></strong></label>
                <InputText type="text" 
                    class="w-full mt-2" 
                    v-model="matricula" />
            </div>
        </div>
        <g-recaptcha :dataSitekey="siteKey" 
                dataLanguage="pt-BR" 
                :dataCallback="callback" 
                v-if="nodeEnv != 'local'"
                />
        <template #footer>
            <Button label="Cancelar" 
                    @click="fecharModal()" 
                    icon="pi pi-times" 
                    class="p-button-text"/>
            <Button label="Enviar" 
                    icon="pi pi-check" 
                    class="p-button-text p-button-danger" 
                    @click="recuperarSenha()" />
        </template>
    </Dialog>
</template>

<script>
    import RecuperarSenhaService from '../../service/RecuperarSenhaService';
    import gRecaptcha from '../../utilities/Recaptcha.vue';

    export default {
        components: {
            gRecaptcha,
        },
        emits: ['fechar'],
        props: {
            visivelProp: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                matricula: null,
                matriculaInvalida: false,
                tokenCaptcha: '',
                siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
                nodeEnv: process.env.NODE_ENV,
            }
        },

        methods: {
            recuperarSenha() {
                if (this.tokenCaptcha == '' && this.nodeEnv != 'local') {
                    this.abrirToast('error', 'Falha', 'Captcha inválido', 3000);
                    return;
                }
                
                if (!this.validarCampos()) return;

                RecuperarSenhaService.recuperarSenha(this.matricula)
                        .then(response => {
                            this.abrirToast('success', 
                                'Sucesso', 
                                `E-mail enviado com sucesso para ${response.data}`, 
                                3000);

                            setTimeout(() => {
                                this.fecharModal();
                            }, "1000");

                            this.matricula = null;
                        })
                        .catch(erro => {
                            this.abrirToast('error', 
                                    'Falha', 
                                    'Falha ao solicitar recuperar senha.', 
                                    3000);
                        });
            },

            validarCampos() {
                let isValid = true;

                if (!this.matricula) {
                    this.matriculaInvalida = true;
                    isValid = false;
                }

                if (!isValid) {
                    this.abrirToast('error', 
                            'Falha', 
                            'Preencher os campos obrigatórios.', 
                            3000);
                    return;
                }

                return isValid;
            },

            abrirToast(tipo, titulo, descricao, tempo) {
                this.$toast.add({
                    severity: tipo, 
                    summary: titulo, 
                    detail: descricao, 
                    life: tempo
                });
            },

            fecharModal() {
                return this.$emit('fechar');
            },

            callback(tokenCaptcha) {
                this.tokenCaptcha = tokenCaptcha;
            },
        }
    }
</script>
