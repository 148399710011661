<template>
    <AutoComplete
        inputStyle="border-radius: 0;"
        field="label"
        v-model="cooperadoSelecionado"
        :placeholder="placeholder"
        :suggestions="cooperadosFiltrados"
        @complete="buscarCooperado"
        @item-select="selecionarCooperado"
        :dropdown="dropdown ? true : false"
    />    
</template>

<script>
    import AuthService from '../service/security/auth.service';
    import StorageService from '../service/StorageService';
    import HistoricoAcessoService from '../service/HistoricoAcessoService';

    export default {
        emits: ['mensagemErro', 'sucesso'],

        props: {
			placeholder: {
				type: String,
				required: false
			},

            dropdown: {
                type: Boolean,
                required: false
            }
		},

        data() {
            return {
                cooperadoSelecionado: null,
                cooperadosFiltrados: [],
                ip: '',
            }
        },

        created() {
            // this.getIp();
        },

        methods: {
            buscarCooperado(event) {
                setTimeout(() => {
                    const consulta = !event.query.trim().length ? 'a' : event.query.trim();
                    AuthService.getCooperados(consulta)
                        .then(({ data }) => {
                            const cooperados = data.map(item => {
                                item['label'] = `${ item.matricula } - ${ item.nome.toUpperCase() }`;
                                return item;
                            });
                            
                            this.cooperadosFiltrados = [...cooperados];
                        });
                }, 250);
            },

            selecionarCooperado() {
                if(this.cooperadoSelecionado) {
                    const infoAdmin = StorageService.getAdmin();

                    if(infoAdmin && infoAdmin.login && infoAdmin.login.username) {
                        this.$store.dispatch("auth/subLoginCooperado", {
                            username: infoAdmin.login.username,
                            matriculaCooperado: this.cooperadoSelecionado.matricula
                        })
                            .then(async (data) => {
                                await this.getIp();
                                let historicoAcesso = {
                                    idCooperado: this.cooperadoSelecionado.matricula,
                                    idUsuario: infoAdmin.matricula,
                                    web: true,
                                    ip: this.ip,
                                }
                                HistoricoAcessoService.salvar(historicoAcesso)
                                    .finally(() => {
                                        this.$router.push("/");
                                        this.$emit('sucesso', data);
                                    });
                            })
                            .catch(error => {
                                this.$emit('mensagemErro', 'Falha ao buscar informações do cooperado selecionado.');
                            });
                    }
                    else {
                        this.$emit('mensagemErro', 'Falha ao buscar informações do cooperado selecionado.');
                    }
                }
            },

            async getIp() {
                await fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    this.ip = ip;
                });
            },
        }
    }
</script>