<template>
    
</template>

<script lang="js">
    import webSdk from './web-sdk';
    import initSdk from './init-sdk';

    export default {
        name: 'Chatbot',

        data() {
            return {
                nome: this.$route.query.nome,
                matricula: this.$route.query.matricula,
            }
        },
        mounted() {
            initSdk.initSdk('Bots', false, this.nome, '', this.matricula);
        }
    }
</script>

<style>
    .oda-chat-wrapper {
        bottom: 30px;
    }

    .oda-chat-wrapper .oda-chat-button img {
        width: 50px;
        height: 50px;
    }

    .oda-chat-wrapper .oda-chat-button  {
        width: 55px;
        height: 55px;
        border-radius:50%;
    }

    .oda-chat-wrapper .oda-chat-popup li .oda-chat-text {
        color:#000;
    }

    .oda-chat-wrapper {
        --color-header-background:#00854a; 
        --color-header-text:#FBFBFB;
        --color-header-button-fill:#FBFBFB;
        --color-header-button-fill-hover:#FBFBFB;
        --color-actions-background:#00854a;
        --color-actions-background-focus:#025b33;
        --color-actions-background-hover:#016e3d;
        --color-actions-border:#00854a;
        --color-actions-text:#FBFBFB;
        --color-actions-text-focus:#FBFBFB;
        --color-actions-text-hover:#FBFBFB;
        --color-global-actions-background:#00854a;
        --color-global-actions-background-focus:#025b33;
        --color-global-actions-background-hover:#016e3d;
        --color-global-actions-border:#00854a;
        --color-global-actions-text:#FBFBFB;
        --color-global-actions-text-focus:#FBFBFB;
        --color-global-actions-text-hover:#FBFBFB;
        --color-rating-star:#A6DBA7;
        --color-rating-star-fill:#00854a;
        --color-visualizer:#00854a;
        --color-popup-background: #fff;
        --color-popup-text: #161513;
        --color-popup-button-background: #f9fbfa;
        --color-popup-button-text: #00854a;
        --color-links:#00854a;
        --color-card-nav-button:#00854a;
        --color-card-nav-button-focus:#025b33;
        --color-card-nav-button-hover:#016e3d;
    }

    .oda-chat-message-actions .oda-chat-action-postback,
    .oda-chat-card-actions .oda-chat-action-postback {
        width: 100% !important;
        justify-content: center !important;
    }

    .oda-chat-message-global-actions .oda-chat-action-postback {
        margin-left: 5%;
        padding-left: 5%;
        justify-content: center !important;
        width: 80% !important;
    }

    .oda-chat-wrapper .oda-chat-header .oda-chat-logo {
        width: auto !important;
    }
</style>