<template>
    <Toast />
	<div class="pages-body login-page flex flex-column" v-bind:style="{ 'background-image': 'url(data:image/png;base64,' + img + ')' , 'background-size': 'cover' }">
        
        <div class="align-self-center mt-auto mb-auto">
            <div class="pages-panel card flex flex-column" style="min-width: 420px;">
                <div class="pages-header px-3 py-1">
                    <div class="grid col-12">
                        <div class="col-12">
                            <img src="layout/images/login/logo_portal_branca_login.png" alt="">
                        </div>
                    </div>
                </div>

                <h4>Novo Cadastro</h4>

                <div class="pages-detail mb-6 px-6">
                    Bem-vindo!
                    <br />Preencha todos os campos abaixo para ativar o acesso:
                </div>

                <div class="input-panel flex flex-column px-3">
                    <div class="dv-login">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText 
                                        placeholder="Matricula"
                                        type="text" 
                                        :class="['p-inputtext', 'p-component', {'p-invalid': matriculaInvalida}]" 
                                        id="matricula" 
                                        v-model="cooperado.matricula" 
                                        maxlength="6" 
                                        @blur="validarCooperado($event)" />
                            </span>
                        </div>

                        <div class="p-inputgroup mt-3 tipo-pessoa">
                            <span class="p-float-label">
                                <RadioButton id="pessoaFisica" name="tipoPessoa" value="FISICA" v-model="cooperado.tipoPessoa" />
								<label for="pessoaFisica">Pessoa Física</label>
                            </span>
                            <span class="p-float-label">
                                <RadioButton id="pessoaJuridica" name="tipoPessoa" value="JURIDICA" v-model="cooperado.tipoPessoa" />
                                <label for="pessoaJuridica">Pessoa Jurídica</label>
                            </span>
                        </div>

                        <div class="p-inputgroup mt-3" v-if="cooperado.tipoPessoa == 'FISICA'">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-calendar"></i>
                            </span>
                            <span class="p-float-label">
                                <InputMask placeholder="Data de Nascimento" 
                                        type="text" 
                                        :class="['p-inputtext', 'p-component', {'p-invalid': dataNascimentoInvalida}]" 
                                        id="dataNascimento" 
                                        v-model="dataNascimento" 
                                        mask="99/99/9999" 
                                        @blur="dataNascimentoInvalida = false" 
                                        :disabled="!matriculaExiste" />
                            </span>
                        </div>

                        <div class="p-inputgroup mt-3" v-if="cooperado.tipoPessoa == 'FISICA'">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-id-card"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText placeholder="RG" 
                                        type="text" 
                                        :class="['p-inputtext', 'p-component', {'p-invalid': registroGeralInvalida}]" 
                                        id="registroGeral" 
                                        v-model="cooperado.registroGeral" 
                                        @blur="registroGeralInvalida = false" 
                                        :disabled="!matriculaExiste" />
                            </span>
                        </div>

                        <div class="p-inputgroup mt-3" v-if="cooperado.tipoPessoa == 'FISICA'">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-id-card"></i>
                            </span>
                            <span class="p-float-label">
                                <InputMask placeholder="CPF" 
                                        type="text" 
                                        :class="['p-inputtext', 'p-component', {'p-invalid': cpfInvalida}]" 
                                        id="cpf" 
                                        v-model="cooperado.cpf" 
                                        mask="999.999.999-99" 
                                        @blur="cpfInvalida = false" 
                                        :disabled="!matriculaExiste" />
                            </span>
                        </div>

                        <div class="p-inputgroup mt-3" v-if="cooperado.tipoPessoa == 'JURIDICA'">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-id-card"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText placeholder="Inscrição Estadual" 
                                        type="text" 
                                        :class="['p-inputtext', 'p-component', {'p-invalid': inscricaoEstadualInvalida}]" 
                                        id="registroGeral" 
                                        v-model="cooperado.inscricaoEstadual" 
                                        @blur="inscricaoEstadualInvalida = false" 
                                        :disabled="!matriculaExiste" />
                            </span>
                        </div>

                        <div class="p-inputgroup mt-3" v-if="cooperado.tipoPessoa == 'JURIDICA'">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-id-card"></i>
                            </span>
                            <span class="p-float-label">
                                <InputMask placeholder="CNPJ" 
                                        type="text" 
                                        :class="['p-inputtext', 'p-component', {'p-invalid': cnpjInvalida}]" 
                                        id="cpf" 
                                        v-model="cooperado.cnpj" 
                                        mask="99.999.999/9999-99" 
                                        @blur="cnpjInvalida = false" 
                                        :disabled="!matriculaExiste" />
                            </span>
                        </div>

                        <div class="p-inputgroup mt-3">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-envelope"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText placeholder="E-mail" 
                                        type="text" 
                                        :class="['p-inputtext', 'p-component', {'p-invalid': emailInvalida}]" 
                                        id="email" 
                                        v-model="cooperado.email" 
                                        @blur="emailInvalida = false" 
                                        :disabled="!matriculaExiste"  />
                            </span>
                        </div>

                        <div class="p-inputgroup mt-3">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-mobile"></i>
                            </span>
                            <span class="p-float-label">
                                <InputMask placeholder="Celular" 
                                        type="text" 
                                        :class="['p-inputtext', 'p-component', {'p-invalid': celularInvalida}]" 
                                        id="celular" 
                                        v-model="cooperado.celular" 
                                        mask="(99) 99999-9999" 
                                        @blur="celularInvalida = false" 
                                        :disabled="!matriculaExiste" />
                            </span>
                        </div>


                        <div class="p-inputgroup mt-3">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-lock"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText placeholder="Senha" 
                                        type="password" 
                                        :class="['p-inputtext', 'p-component', {'p-invalid': senhaInvalida}]" 
                                        id="senha" 
                                        v-model="cooperado.senha" 
                                        @blur="senhaInvalida = false" 
                                        :disabled="!matriculaExiste" />
                            </span>
                        </div>

                        <div class="p-inputgroup mt-3">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-lock"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText placeholder="Confirmar Senha" 
                                        type="password" 
                                        :class="['p-inputtext', 'p-component', {'p-invalid': confirmarSenhaInvalida}]" 
                                        id="confirmarSenha" 
                                        v-model="confirmarSenha" 
                                        @blur="confirmarSenhaInvalida = false" 
                                        :disabled="!matriculaExiste" />
                            </span>
                        </div>

                        <div class="p-inputgroup mt-4">
                            <span class="p-float-label">
                                <Checkbox v-model="termoUso" 
                                        :binary="true" 
                                        :class="[{'p-invalid': termoUsoInvalida}]" 
                                        :disabled="!matriculaExiste" />
                                &nbsp; Concordo com os
                                &nbsp;<a :href="urlTermoUso" 
                                        target="_blank" 
                                        class="termos">Termos de Uso</a>
                            </span>
                        </div>

                        <div class="p-inputgroup mt-4">
                            <span class="p-float-label">
                                <Checkbox v-model="politicaPrivacidade" 
                                        :binary="true" 
                                        :class="[{'p-invalid': politicaPrivacidadeInvalida}]" 
                                        :disabled="!matriculaExiste" />
                                &nbsp; Concordo com as
                                &nbsp;<a :href="urlPoliticaPrivacidade" 
                                        target="_blank" 
                                        class="termos">Política de Privacidade</a>
                            </span>
                        </div>

                        <g-recaptcha :dataSitekey="siteKey" 
                                dataLanguage="pt-BR" 
                                :dataCallback="callback" 
                                v-if="nodeEnv != 'local'"
                                />

                        <button :disabled="termoUso == false || politicaPrivacidade == false" 
                                class="p-button p-component mt-6" @click="salvarCooperado">
                            <i class="pi pi-sign-in" style="margin-right: 10px;"/>
                            <span>SALVAR</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import AuthService from '../service/security/auth.service';
    import TermoService from '../service/TermoService';
    //import EstabelecimentoService from '../service/EstabelecimentoService';
    import gRecaptcha from '../utilities/Recaptcha.vue';
    import CooperadoService from '../service/CooperadoService';
    import Formatacao from '../utilities/Formatacao';
    import ImagemFundoService from '../service/ImagemFundo';

    export default {
        name: "NovoCadastro",
        components: {
            gRecaptcha,
        },
        data() {
            return {
                loading: false,
                cooperado: {
                    tipoPessoa: 'FISICA',
                },
                dataNascimento: '',
                confirmarSenha: '',
                //estabelecimentos: [],
                //estabelecimentoSelecionado: null,
                
                matriculaInvalida: false,
                dataNascimentoInvalida: false,
                registroGeralInvalida: false,
                cpfInvalida: false,
                inscricaoEstadualInvalida: false,
                cnpjInvalida: false,
                emailInvalida: false,
                celularInvalida: false,
                senhaInvalida: false,
                confirmarSenhaInvalida: false,
                termoUsoInvalida: false,
                politicaPrivacidadeInvalida: false,
                //estabelecimentoSelecionadoInvalida: false,

                termoUso: false,
                politicaPrivacidade: false,
                urlTermoUso: '',
                urlPoliticaPrivacidade: '',
                matriculaExiste: false,
                tokenCaptcha: '',
                siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
                nodeEnv: process.env.NODE_ENV,
                img:'',
            };
        },
        created() {
            this.buscarTermoUso();
            this.buscarPoliticaPrivacidade();
            //this.buscarEstabelecimentos();
            this.recuperarImagemFundo();
        },
        methods: {
            salvarCooperado() {
                if (this.tokenCaptcha == '' && this.nodeEnv != 'local') {
                    this.abrirToast('error', 'Falha', 'Captcha inválido', 3000);
                    return;
                }

                this.loading = true;
                if (!this.compararSenhaEConfirmarSenha(this.cooperado)) return;
                if (!this.validarCamposObrigatorios(this.cooperado)) return;

                this.cooperado.dataAceiteTermoUso = new Date().toISOString().substring(0,23);
                this.cooperado.dataAceitePoliticaPrivacidade = new Date().toISOString().substring(0,23);
                this.cooperado.estabelecimentoId = null;//this.estabelecimentoSelecionado.codigo;
                this.cooperado.dataNascimento = Formatacao.formatDateToISO8601(this.dataNascimento)
                
                CooperadoService.salvarNovoCadastro(this.cooperado)
                    .then(response => {
                        this.abrirToast('success', 'Sucesso', 'Cadastro realizado com sucesso', 3000);
                        setTimeout(() => {
                            this.$router.push(`/login`);
                        }, "3010");
                    })
                    .catch(error => {
                        this.abrirToast('error', 'Falha', error.response.data.message_error, 3000);
                    });
            },

            compararSenhaEConfirmarSenha(cooperado) {
                let isValid = true;
                
                if (cooperado) {
                    if (cooperado.senha && this.confirmarSenha && cooperado.senha != this.confirmarSenha) {
                        this.abrirToast('error',
                                'Falha', 
                                `Senha e Confirmar Senha estão diferente!`, 
                                3000);
                        
                        isValid = false;

                        return;
                    }
                }

                return isValid;
            },

            validarCamposObrigatorios(cooperado) {
                let isValid = true;

                if (cooperado.matricula == null || cooperado.matricula == '') {
                    isValid = false;
                    this.matriculaInvalida = true;
                }

                if (cooperado.email == null || cooperado.email == '') {
                    isValid = false;
                    this.emailInvalida = true;
                }

                if (cooperado.celular == null || cooperado.celular == '') {
                    isValid = false;
                    this.celularInvalida = true;
                }

                // if (this.estabelecimentoSelecionado == null || this.estabelecimentoSelecionado == '') {
                //     isValid = false;
                //     this.estabelecimentoSelecionadoInvalida = true;
                // }

                if (cooperado.senha == null || cooperado.senha == '') {
                    isValid = false;
                    this.senhaInvalida = true;
                }

                if (this.confirmarSenha == null || this.confirmarSenha == '') {
                    isValid = false;
                    this.confirmarSenhaInvalida = true;
                }

                if (this.termoUso == false) {
                    isValid = false;
                    this.termoUsoInvalida = true;
                }

                if (this.politicaPrivacidade == false) {
                    isValid = false;
                    this.politicaPrivacidadeInvalida = true;
                }

                if (cooperado.tipoPessoa == 'FISICA') {
                    if (cooperado.registroGeral == null || cooperado.registroGeral == '') {
                        isValid = false;
                        this.registroGeralInvalida = true;
                    }

                    if (cooperado.cpf == null || cooperado.cpf == '') {
                        isValid = false;
                        this.cpfInvalida = true;
                    }

                    isValid = this.validarDataNascimento(this.dataNascimento);
                } else {
                    if (cooperado.inscricaoEstadual == null || cooperado.inscricaoEstadual == '') {
                        isValid = false;
                        this.inscricaoEstadualInvalida = true;
                    }

                    if (cooperado.cnpj == null || cooperado.cnpj == '') {
                        isValid = false;
                        this.cnpjInvalida = true;
                    }
                }

                if (!isValid) {
                    this.abrirToast('error', 'Falha', 'Preencher os campos obrigatório', 3000);
                }

                return isValid;
            },

            validarDataNascimento(dataNascimento) {
                if (dataNascimento == null || dataNascimento == '') {
                    this.dataNascimentoInvalida = true;
                    return false;
                }
                
                let dia = dataNascimento.split("/")[0];
                let mes = dataNascimento.split("/")[1];
                let ano = dataNascimento.split("/")[2];
                let dataUS = `${mes}/${dia}/${ano}`;
                
                let isValid = true;
                let dataAtual = new Date();
                let data = new Date(dataUS);

                if (!data instanceof Date || isNaN(data)) {
                    isValid = false;
                }

                if (data > dataAtual) {
                    isValid = false;
                }

                if (!isValid) {
                    this.dataNascimentoInvalida = true;
                }

                return isValid;
            },

            abrirToast(tipo, titulo, descricao, tempo) {
                this.$toast.add({
                    severity: tipo, 
                    summary: titulo, 
                    detail: descricao, 
                    life: tempo
                });
            },

            buscarTermoUso() {
                TermoService.buscarTermoUso()
                    .then(response => {
                        this.urlTermoUso = response.data;
                    })
                    .catch(error => {
                    });
            },

            buscarPoliticaPrivacidade() {
                TermoService.buscarPoliticaPrivacidade()
                    .then(response => {
                        this.urlPoliticaPrivacidade = response.data;
                    })
                    .catch(error => {
                    });
            },

            // buscarEstabelecimentos() {
            //     EstabelecimentoService.getEstabelecimentos()
            //             .then(response => {
            //                 this.estabelecimentos = response.data;
            //             })
            //             .catch(error => {
                            
            //             });
            // },
            
            validarCooperado(e) {
                AuthService.validarCooperado(e.target.value)
                    .then( ({data}) => {
                        if (data != null) {
                            this.matriculaExiste = true;
                        }
                    })
                    .catch(error => {
                        this.matriculaExiste = false;
                        if (error.response.data.code_error  == 404) {
                            this.abrirToast('error', 
                                    'Falha', 
                                    'Matrícula não localizada no sistema, por favor entre em contato com sua unidade.', 
                                    5000);
                        } else {
                            this.abrirToast('error', 'Falha', error.response.data.message_error, 3000);
                        }
                    });
            },

            callback(tokenCaptcha) {
                this.tokenCaptcha = tokenCaptcha;
            },

            recuperarImagemFundo(){
                ImagemFundoService.recuperarImagemFundo("site")
                    .then(response => {
                
                        let IMAGEM = response.data;
                        this.img = response.data.base64;
                    })
                    .catch(error => {
                        console.error("Erro ao recuperar a imagem de fundo: ", error);
                    });
            },

            // async recuperarImagemFundo1(){
            //      let Imagem = await ImagemFundoService.recuperarImagemFundo("site");
                
            //      console.log("Imagem: ", Imagem);
            //      console.log("base64", Imagem.data.base64);
            //      this.img = Imagem.data.base64;
            // },

            // async recuperarImagemFundo2(){
            //     try {
            //         let response = await ImagemFundoService.recuperarImagemFundo("site");
            //         if (response && response.data && response.data.base64) {
            //             console.log("Imagem recuperada com sucesso:", response.data.base64);
            //             this.img = response.data.base64;
            //             console.log("saindo...");
            //         } else {
            //             console.error("Resposta inválida ao recuperar a imagem de fundo", response);
            //         }
            //     } catch (error) {
            //         console.error("Erro ao recuperar a imagem de fundo: ", error);
            //         // Lidar com o erro adequadamente
            //     }
            // },


        },
    };
</script>

<style scoped>
    .label a{
        color: rgba(70,130,180) !important;
    }
    span {  
        font-weight: bold;
    }

    .dv-login{
        border: solid 1px #cecece;
        background-color: #fbfbfb !important;
        padding: 15px;
        border-radius: 10px;
        align-items: center; 
        margin-bottom: 10px !important;
    }
    .p-button{
        display: block;
        margin: 0 auto;
        width: 100%;
        border: solid 1px rgb(76,146,83, 0.9) !important;
        background: rgba(76,146,83) !important;
    }
    .p-button:hover {
        border: solid 1px rgb(76,146,83, 0.8) !important;
        background: rgb(76, 146, 83, 0.8) !important;
        color: rgba(0,0,0);
    }
    .alert-danger{
        color: rgb(250, 6, 6);
        font-weight: bold;
        margin-top: 15px !important;
        margin-bottom: 10px !important;
    }

    .pages-body.login-page .pages-panel .pages-header {
        background: rgb(76, 146, 83) !important;
        max-width: 400px !important;;
    }

    .pages-body .pages-panel {
        opacity: 0.9 !important;
        max-width: 500px !important;
    }

    .p-float-label {
        text-align: left;
    }
    .termos {
        text-decoration: underline;
    }

    .p-button:disabled {
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.38) !important;
        opacity: 1;
    }

    .tipo-pessoa {
        padding: 0.75rem ;
    }

    .tipo-pessoa label {
        margin-left: 16px;
    }
</style>
