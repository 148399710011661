import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/estabelecimento`;

class EstabelecimentoService {

    getEstabelecimentos(params) {
        return axios.get(API_BASE_URL + RESOURCE_V1, {params});
    }
}

export default new EstabelecimentoService();