import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperadoAuth;
const RESOURCE_V1 = `/v1/termo`;

class TermoService {
    buscarTermoUso(pagina, qtdRegistro) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/uso`);
    }

    buscarPoliticaPrivacidade(matricula) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/politica-privacidade`);
    }
}

export default new TermoService();
