import StorageKeys from "../config/StorageKeys";

class StorageService {
    getAuth() {
        let auth = localStorage.getItem(StorageKeys.Auth);

        if(auth == null) {
            return null;
        }
        else {
            return JSON.parse(auth);
        }
    }

    setAuth(auth) {
        if(auth == undefined || auth == null) {
            localStorage.removeItem(StorageKeys.Auth);
        }
        else {
            localStorage.setItem(StorageKeys.Auth, JSON.stringify(auth));
        }
    }

    getUser() {
        let user = localStorage.getItem(StorageKeys.User);

        if(user == null) {
            return null;
        }
        else {
            return JSON.parse(user);
        }
    }

    setUser(user) {
        if(user == undefined || user == null) {
            localStorage.removeItem(StorageKeys.User);
        }
        else {
            localStorage.setItem(StorageKeys.User, JSON.stringify(user));
        }
    }

    getAdmin() {
        let admin = localStorage.getItem(StorageKeys.Admin);

        if(admin == null) {
            return null;
        }
        else {
            return JSON.parse(admin);
        }
    }

    setAdmin(admin) {
        if(admin == undefined || admin == null) {
            localStorage.removeItem(StorageKeys.Admin);
        }
        else {
            localStorage.setItem(StorageKeys.Admin, JSON.stringify(admin));
        }
    }

    validateSystemOptions(user) {
       
        if(this.isNullOrEmpty(user.corCabecalho)){
            user.corCabecalho = "green";
        }

        if(this.isNullOrEmpty(user.corComponentes)){
            user.corComponentes = "green"; 
        }

        if(this.isNullOrEmpty(user.corMenu)){
            user.corMenu = "light";
        }

        if(this.isNullOrEmpty(user.estiloCampos)){
            user.estiloCampos = "";
        }

        if(this.isNullOrEmpty(user.fonte)){
            user.fonte = "14";
        }

        if(this.isNullOrEmpty(user.menu)){
            user.menu = "static";
        }

        if(this.isNullOrEmpty(user.menuPosition)){
            user.menuPosition = "top";
        }

        if(this.isNullOrEmpty(user.rippleEffect)){
            user.rippleEffect = "";
        }

        if(this.isNullOrEmpty(user.rtl)){
            user.rtl = false;
        }

        if(this.isNullOrEmpty(user.tema)){
            user.tema = "light";
        }

        return user;
    }

    isNullOrEmpty(value) {
        return value == null || value == "";
    }

    setMostrarDadosSensiveis(arg) {
        localStorage.setItem("mostrarDadosSensiveis", arg);
    }

    getMostrarDadosSensiveis() {
        let dadosSensiveis = localStorage.getItem("mostrarDadosSensiveis");

        if(dadosSensiveis == null) {
            return true;
        }
        else {
            //return dadosSensiveis;
            if(dadosSensiveis == "true"){
                return true;
            }
            return false;
        }
    }

}

export default new StorageService();