<template>
    <header id="js-header" class="u-header u-header--static">

        <div style="position:relative"
            class="u-header__section u-header__section--light g-bg-white g-transition-0_3 g-py-10">

            <nav class="js-mega-menu navbar navbar-toggleable-md hs-menu-initialized hs-menu-horizontal">

                <div class="container" style="width: 98% !important">
                    <button
                        class="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-right-0"
                        type="button" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar"
                        data-toggle="collapse" data-target="#navBar">

                        <span class="hamburger hamburger--slider">
                            <span class="hamburger-box">
                                <span class="hamburger-inner"></span>
                            </span>
                        </span>

                    </button>

                    <a class="navbar-brand" href="www.integrada.coop.br">
                        <img style="height: 40px; margin-top: 10px; margin-bottom: 10px"
                            src="https://www.integrada.coop.br/img/logo_coop_integrada.png" alt="Cooperativa Integrada">
                    </a>

                    <div class="collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg g-mr-40--lg"
                        id="navBar">
                        <ul class="navbar-nav text-uppercase g-pos-rel g-font-weight-700 ml-auto" style="font-size: 9pt">


                            <!-- <li class="nav-item hs-has-sub-menu g-mx-10--lg g-mx-15--xl" data-animation-in="fadeIn"
                                data-animation-out="fadeOut">

                                <a id="nav-link--features" class="nav-link g-py-7 g-px-0" href="#" aria-haspopup="true"
                                    aria-expanded="false" aria-controls="nav-submenu--features">A Integrada</a>

                                <ul class="hs-sub-menu list-unstyled u-shadow-v11 g-brd-top g-brd-primary g-brd-top-2 g-min-width-220 g-mt-20 g-mt-10--lg--scrolling animated fadeOut"
                                    id="nav-submenu--features" aria-labelledby="nav-link--features" style="display: none;">

                                    <li class="dropdown-item">
                                        Informações
                                    </li>

                                    <li class="dropdown-item hs-has-sub-menu">
                                        <a id="nav-link--features--pop-ups" class="nav-link" href="sustentabilidade.xhtml"
                                            aria-haspopup="true" aria-expanded="false"
                                            aria-controls="nav-submenu--features--pop-ups">Sustentabilidade</a>

                                        <ul class="hs-sub-menu list-unstyled u-shadow-v11 g-brd-top g-brd-primary g-brd-top-2 g-min-width-220 g-mt-minus-2"
                                            id="nav-submenu--features--pop-ups"
                                            aria-labelledby="nav-link--features--pop-ups" style="display: none;">





                                        </ul>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="cooperativismo.xhtml">Cooperativismo</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="relatorio-atividades.xhtml">Relatório de atividades</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="img/Manual_Marca_Integrada.pdf">Manual de Marca
                                            Integrada</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="marca-integrada.xhtml">Marca Integrada</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="downloads/selo_25_anos.jpg" target="_blank">Selo 25
                                            Anos</a>
                                    </li>

                                </ul>
                            </li> -->


                            <!-- <li class="nav-item hs-has-sub-menu g-mx-10--lg g-mx-15--xl" data-animation-in="fadeIn"
                                data-animation-out="fadeOut">

                                <a id="nav-link--features" class="nav-link g-py-7 g-px-0" href="#" aria-haspopup="true"
                                    aria-expanded="false" aria-controls="nav-submenu--features">Produtos e Serviços</a>

                                <ul class="hs-sub-menu list-unstyled u-shadow-v11 g-brd-top g-brd-primary g-brd-top-2 g-min-width-220 g-mt-20 g-mt-10--lg--scrolling animated fadeOut"
                                    id="nav-submenu--features" aria-labelledby="nav-link--features" style="display: none;">

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="recebimento-comercializacao-graos.xhtml">Recebimento e
                                            comercialização de grãos</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="unidade-beneficiadora-sementes.xhtml">Unidade de
                                            Beneficiamento de Sementes</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="comercializacao-insumos.xhtml">Comercialização de
                                            insumos</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="maquinas-equipamentos-acessorios.xhtml">Máquinas e
                                            equipamentos</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="portifolio-cultivares.xhtml">Portifólio de Cultivares</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="assistencia-tecnica.xhtml">Assistência técnica</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="nossas-unidades.xhtml">Mapa de atuação</a>
                                    </li>

                                </ul>
                            </li> -->


                            <!-- <li class="nav-item hs-has-sub-menu g-mx-10--lg g-mx-15--xl" data-animation-in="fadeIn"
                                data-animation-out="fadeOut">

                                <a id="nav-link--features" class="nav-link g-py-7 g-px-0" href="#" aria-haspopup="true"
                                    aria-expanded="false" aria-controls="nav-submenu--features">Indústrias</a>

                                <ul class="hs-sub-menu list-unstyled u-shadow-v11 g-brd-top g-brd-primary g-brd-top-2 g-min-width-220 g-mt-20 g-mt-10--lg--scrolling animated fadeOut"
                                    id="nav-submenu--features" aria-labelledby="nav-link--features" style="display: none;">

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="industria-milho.xhtml">Unidade Industrial de Milho</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="industria-sucos.xhtml">Unidade Industrial de Sucos</a>
                                    </li>

                                    <li class="dropdown-item">
                                        <a class="nav-link" href="industria-racao.xhtml">Unidade Industrial de Rações</a>
                                    </li>

                                </ul>
                            </li> -->



                            <!-- <a class="nav-link g-py-7 g-px-0" href="fale-conosco.xhtml" aria-haspopup="true"
                                aria-expanded="false" aria-controls="nav-submenu--features">Ouvidoria</a> -->
                        </ul>
                    </div>


                    <!-- <div>
                        <div class="d-inline-block g-pos-rel g-valign-middle g-pl-30 g-pl-0--lg">
                            <a class="btn g-font-size-13 text-uppercase g-py-10 g-px-15 btn-cooperado-mobile"
                                style="float:right;" href="https://www.integradalojas.com.br/" target="_blank">
                                <img src="https://www.integrada.coop.br/img/logo_coop_integrada.png" alt="Loja Integrada"
                                    style="margin-top: -5px; margin-left: -10px; width: 150px;">
                            </a>
                        </div>
                    </div>

                    <div>
                        <div class="d-inline-block g-pos-rel g-valign-middle g-pl-30 g-pl-0--lg btn-cooperado"
                            id="portalCooperado">
                            <a href="http://api.whatsapp.com/send?1=pt_BR&amp;phone=554332947008"
                                style="float:right; text-decoration: none; margin-right: 30px; margin-bottom: 5px; margin-top: 5px"
                                target="_blank" title="Whatsapp IRIS Integrada 43 3294-7008">
                                <i class="fa fa-whatsapp" style="font-size:16pt;"></i>
                            </a>

                            <a href="http://portal.integrada.coop.br"
                                style="margin-top:2px; float:right; text-decoration: none; margin-right: 20px; margin-bottom: 5px; margin-top: 6px"
                                target="_blank" title="Portal do Colaborador">
                                <i class="fa fa-user-circle" style="font-size:14pt;"></i>
                            </a>

                            <a href="https://outlook.office365.com"
                                style="float:right; text-decoration: none; margin-right: 20px; margin-bottom: 5px; margin-top: 5px"
                                target="_blank" title="Webmail">
                                <i class="fa fa-envelope-square" style="font-size:16pt"></i>
                            </a>

                            <a href="http://arquivos.integrada.coop.br"
                                style="float:right; text-decoration: none; margin-right: 20px; margin-bottom: 5px; margin-top: 5px"
                                target="_blank" title="Gerenciador de arquivos">
                                <i class="fa fa-cloud-download" style="font-size:16pt;"></i>
                            </a>
                            <br>

                            <a class="btn u-btn-outline-primary g-font-size-13 text-uppercase g-py-10 g-px-15 btn-cooperado-mobile"
                                style="float:right;" href="https://cooperado.integrada.coop.br" target="_blank">
                                Portal do Cooperado
                            </a>
                        </div>
                    </div>

                    <div>
                        <div class="d-inline-block g-pos-rel g-valign-middle g-pl-30 g-pl-0--lg btn-sat">
                            <a class="btn u-btn-outline-primary btn-sat-mobile"
                                style="height: 38px; width: 80px; margin-left: 25px; margin-bottom: 10px; margin-top: 10px"
                                href="https://sat.integrada.coop.br" target="_blank"
                                title="Sistema de Autorização de Trabalho">
                                <img src="img/logo_sat_interno_70_29.png" alt="Sistema de Autorização de Trabalho"
                                    style="margin-top: -5px; margin-left: -10px">
                            </a>
                        </div>
                    </div> -->

                </div>
            </nav>
        </div>
        <div class="u-header__section u-header__section--light g-bg-white g-transition-0_3 g-py-10">
        </div>
        <form id="j_idt63" name="j_idt63" method="post" action="/quem-somos-historia.xhtml"
            enctype="application/x-www-form-urlencoded">
            <input type="hidden" name="j_idt63" value="j_idt63">
            <div id="j_idt63:j_idt64"
                class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-shadow ui-hidden-container ui-draggable ui-resizable"
                style="padding-bottom: 100px; width: 350px; height: auto;" role="dialog"
                aria-labelledby="j_idt63:j_idt64_title" aria-hidden="true">
                <div class="ui-dialog-titlebar ui-widget-header ui-helper-clearfix ui-corner-top ui-draggable-handle"><span
                        id="j_idt63:j_idt64_title" class="ui-dialog-title">Definição de cookies</span><a href="#"
                        class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all" aria-label="Close"
                        role="button"><span class="ui-icon ui-icon-closethick"></span></a></div>
                <div class="ui-dialog-content ui-widget-content" style="height: 94.3%;">
                    <div style="font-size:12px; text-align: justify; padding: 5px; overflow-y: auto; max-height: 500px;"
                        class="g-color-black">
                        <p class="p-m-0 text-justify">
                            Quando visita um website, este pode armazenar ou recolher informações no seu navegador,
                            principalmente na forma de cookies.
                            Esta informação pode ser sobre si, as suas preferências ou o seu dispositivo e é utilizada
                            principalmente para fazer o
                            website funcionar conforme o esperado. Uma vez que respeitamos o seu direito à privacidade, pode
                            optar por não permitir
                            alguns tipos de cookies. No entanto, o bloqueio de alguns tipos de cookies pode afetar a sua
                            experiência no website e os serviços que
                            podemos oferecer.
                        </p>
                        <table id="j_idt63:j_idt66" class="ui-panelgrid ui-widget ui-noborder" role="grid">
                            <tbody>
                                <tr class="ui-widget-content" role="row">
                                    <td role="gridcell" class="ui-panelgrid-cell">
                                        <p><strong>Escolha a forma de interagir com nosso site:</strong></p>
                                    </td>
                                </tr>
                                <tr class="ui-widget-content" role="row">
                                    <td role="gridcell" class="ui-panelgrid-cell">
                                        <div id="j_idt63:j_idt68" class="ui-chkbox ui-widget"
                                            title="Estes cookies são necessários para que o website funcione e não podem ser desligados nos nossos sistemas. Normalmente, eles só são configurados em resposta a ações levadas a cabo por si e que correspondem a uma solicitação de serviços, tais como definir as suas preferências de privacidade, iniciar sessão ou preencher formulários. Pode configurar o seu navegador para bloquear ou alertá-lo(a) sobre esses cookies, mas algumas partes do website não funcionarão. Estes cookies não armazenam qualquer informação pessoal identificável.">
                                            <div class="ui-helper-hidden-accessible"><input id="j_idt63:j_idt68_input"
                                                    name="j_idt63:j_idt68_input" type="checkbox" autocomplete="off"
                                                    checked="checked" aria-checked="true" disabled="disabled"></div>
                                            <div
                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default ui-state-active ui-state-disabled">
                                                <span class="ui-chkbox-icon ui-icon ui-icon-check ui-c"></span></div><span
                                                class="ui-chkbox-label">Cookies Essenciais</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="ui-widget-content" role="row">
                                    <td role="gridcell" class="ui-panelgrid-cell">
                                        <div id="j_idt63:j_idt69" class="ui-chkbox ui-widget"
                                            title="Estes cookies permitem-nos contar visitas e fontes de tráfego, para que possamos medir e melhorar o desempenho do nosso website. Eles ajudam-nos a saber quais são as páginas mais e menos populares e a ver como os visitantes se movimentam pelo website. Todas as informações recolhidas por estes cookies são agregadas e, por conseguinte, anónimas. Se não permitir estes cookies, não saberemos quando visitou o nosso site.">
                                            <div class="ui-helper-hidden-accessible"><input id="j_idt63:j_idt69_input"
                                                    name="j_idt63:j_idt69_input" type="checkbox" autocomplete="off"
                                                    aria-checked="false"
                                                    onchange="PrimeFaces.ab({s:&quot;j_idt63:j_idt69&quot;,e:&quot;change&quot;,p:&quot;j_idt63:j_idt69&quot;,g:false});">
                                            </div>
                                            <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-icon ui-icon-blank ui-c"></span></div><span
                                                class="ui-chkbox-label">Cookies de Desempenho</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="ui-widget-content" role="row">
                                    <td role="gridcell" class="ui-panelgrid-cell">
                                        <div id="j_idt63:j_idt70" class="ui-chkbox ui-widget"
                                            title="Estes cookies podem ser estabelecidos através do nosso site pelos nossos parceiros de publicidade. Podem ser usados por essas empresas para construir um perfil sobre os seus interesses e mostrar-lhe anúncios relevantes em outros websites. Eles não armazenam diretamente informações pessoais, mas são baseados na identificação exclusiva do seu navegador e dispositivo de internet. Se não permitir estes cookies, terá menos publicidade direcionada.">
                                            <div class="ui-helper-hidden-accessible"><input id="j_idt63:j_idt70_input"
                                                    name="j_idt63:j_idt70_input" type="checkbox" autocomplete="off"
                                                    aria-checked="false"
                                                    onchange="PrimeFaces.ab({s:&quot;j_idt63:j_idt70&quot;,e:&quot;change&quot;,p:&quot;j_idt63:j_idt70&quot;,g:false});">
                                            </div>
                                            <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-icon ui-icon-blank ui-c"></span></div><span
                                                class="ui-chkbox-label">Cookies de Publicidade</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="ui-widget-content" role="row">
                                    <td role="gridcell" class="ui-panelgrid-cell">
                                        <div id="j_idt63:j_idt71" class="ui-chkbox ui-widget"
                                            title="Estes cookies são estabelecidos por uma série de serviços das redes sociais que adicionamos ao website para permitir que possa partilhar o nosso conteúdo com os seus amigos e conhecidos. Eles são capazes de rastrear a sua navegação por outros websites e criar um perfil sobre os seus interesses. Isso pode afetar o conteúdo e as mensagens que vê noutros websites que visita. Se não permitir estes cookies, talvez não consiga usar ou ver essas ferramentas de partilha.">
                                            <div class="ui-helper-hidden-accessible"><input id="j_idt63:j_idt71_input"
                                                    name="j_idt63:j_idt71_input" type="checkbox" autocomplete="off"
                                                    aria-checked="false"
                                                    onchange="PrimeFaces.ab({s:&quot;j_idt63:j_idt71&quot;,e:&quot;change&quot;,p:&quot;j_idt63:j_idt71&quot;,g:false});">
                                            </div>
                                            <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-icon ui-icon-blank ui-c"></span></div><span
                                                class="ui-chkbox-label">Cookies de Redes sociais</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="ui-widget-content" role="row">
                                    <td role="gridcell" class="ui-panelgrid-cell">
                                        <div id="j_idt63:j_idt72" class="ui-chkbox ui-widget"
                                            title="Estes cookies permitem que o site forneça uma funcionalidade e personalização melhoradas. Podem ser estabelecidos por nós ou por fornecedores externos cujos serviços adicionamos às nossas páginas. Se não permitir estes cookies algumas destas funcionalidades, ou mesmo todas, podem não atuar corretamente.">
                                            <div class="ui-helper-hidden-accessible"><input id="j_idt63:j_idt72_input"
                                                    name="j_idt63:j_idt72_input" type="checkbox" autocomplete="off"
                                                    aria-checked="false"
                                                    onchange="PrimeFaces.ab({s:&quot;j_idt63:j_idt72&quot;,e:&quot;change&quot;,p:&quot;j_idt63:j_idt72&quot;,g:false});">
                                            </div>
                                            <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-icon ui-icon-blank ui-c"></span></div><span
                                                class="ui-chkbox-label">Cookies de Funcionalidade</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="ui-widget-content" role="row">
                                    <td role="gridcell" class="ui-panelgrid-cell"><button id="j_idt63:j_idt73"
                                            name="j_idt63:j_idt73"
                                            class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left"
                                            onclick="PrimeFaces.ab({s:&quot;j_idt63:j_idt73&quot;,e:&quot;click&quot;,p:&quot;j_idt63:j_idt73&quot;,onsu:function(data,status,xhr){PF('dlg1').hide();PF('msgCookieLGPD').hide();}});"
                                            style="float: top;" type="button" role="button" aria-disabled="false"><span
                                                class="ui-button-icon-left ui-icon ui-c fa fa-check"></span><span
                                                class="ui-button-text ui-c">Permitir todos</span></button></td>
                                </tr>
                                <tr class="ui-widget-content" role="row">
                                    <td role="gridcell" class="ui-panelgrid-cell"><button id="j_idt63:j_idt74"
                                            name="j_idt63:j_idt74"
                                            class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left"
                                            onclick="PrimeFaces.ab({s:&quot;j_idt63:j_idt74&quot;,e:&quot;click&quot;,p:&quot;j_idt63:j_idt74&quot;,onsu:function(data,status,xhr){PF('dlg1').hide();PF('msgCookieLGPD').hide();}});"
                                            style="float: top;" type="button" role="button" aria-disabled="false"><span
                                                class="ui-button-icon-left ui-icon ui-c fa fa-check-square-o"></span><span
                                                class="ui-button-text ui-c">Confirmar minhas escolhas</span></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div>
                <div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div>
                <div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div>
                <div class="ui-resizable-handle ui-resizable-w" style="z-index: 90;"></div>
                <div class="ui-resizable-handle ui-resizable-ne" style="z-index: 90;"></div>
                <div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div>
                <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;">
                </div>
                <div class="ui-resizable-handle ui-resizable-sw" style="z-index: 90;"></div>
            </div><input type="hidden" name="javax.faces.ViewState" id="j_id1:javax.faces.ViewState:0"
                value="-7760490024591145068:-4579064162203323744" autocomplete="off">
        </form>
    </header>



    <main>
        <section class="auto-init height-is-based-on-content use-loading" style="height: 400px">

            <div class="divimage dzsparallaxer--target w-100 g-bg-cover "
                style="height: 100%; background-image: url(https://www.integrada.coop.br/img/banner-pagina-quem-somos.jpg);opacity: 1">
            </div>

        </section>

        <div class="shortcode-html">
            <section class="g-bg-gray-light-v5 g-py-30">

                <div class="container">

                    <div class="d-sm-flex text-center">

                        <div class="align-self-center">
                            <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">Informações!</h2>
                        </div>
                        
                    </div>
                </div>
            </section>
        </div>

        <section class="container g-pt-130 g-pb-40">
            <figure class="row">

                <!-- <div class="col-md-8 offset-md-2 col-lg-4 offset-lg-0 g-mb-30">


                    <div class="shortcode-html">
                        <div id="carousel-08-1" class="js-carousel text-center g-mb-20" data-infinite="1"
                            data-arrows-classes="u-arrow-v1 g-absolute-centered--y g-width-35 g-height-40 g-font-size-18 g-color-gray g-bg-white g-mt-minus-10"
                            data-arrow-left-classes="fa fa-angle-left g-left-0"
                            data-arrow-right-classes="fa fa-angle-right g-right-0" data-nav-for="#carousel-08-2">
                            <div class="js-slide">
                                <a class="js-fancybox d-block g-pos-rel" data-fancybox-gallery="lightbox-gallery--08-1"
                                    href="img/historia/AGO1redim.JPG" title="Lightbox Gallery"
                                    data-open-effect="bounceInDown" data-close-effect="bounceOutDown" data-open-speed="1000"
                                    data-close-speed="1000" data-blur-bg="true">
                                    <img class="img-fluid" src="img/historia/AGO1ve.JPG" alt="Image Description" />
                                </a>
                            </div>

                            <div class="js-slide">
                                <a class="js-fancybox d-block g-pos-rel" data-fancybox-gallery="lightbox-gallery--08-1"
                                    href="img/historia/AGO2redim.JPG" title="Lightbox Gallery"
                                    data-open-effect="bounceInDown" data-close-effect="bounceOutDown" data-open-speed="1000"
                                    data-close-speed="1000" data-blur-bg="true">
                                    <img class="img-fluid" src="img/historia/AGO2ve.JPG" alt="Image Description" />
                                </a>
                            </div>

                            <div class="js-slide">
                                <a class="js-fancybox d-block g-pos-rel" data-fancybox-gallery="lightbox-gallery--08-1"
                                    href="img/historia/AGO3redm.JPG" title="Lightbox Gallery"
                                    data-open-effect="bounceInDown" data-close-effect="bounceOutDown" data-open-speed="1000"
                                    data-close-speed="1000" data-blur-bg="true">
                                    <img class="img-fluid" src="img/historia/AGO3ve.JPG" alt="Image Description" />
                                </a>
                            </div>

                            <div class="js-slide">
                                <a class="js-fancybox d-block g-pos-rel" data-fancybox-gallery="lightbox-gallery--08-1"
                                    href="img/historia/AGO4redim.JPG" title="Lightbox Gallery"
                                    data-open-effect="bounceInDown" data-close-effect="bounceOutDown" data-open-speed="1000"
                                    data-close-speed="1000" data-blur-bg="true">
                                    <img class="img-fluid" src="img/historia/AGO4ve.JPG" alt="Image Description" />
                                </a>
                            </div>



                        </div>

                    <div class="d-flex justify-content-between g-mb-10">

                        <ul class="list-inline">
                            <li class="list-inline-item g-mx-2">
                                <a class="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-gray-light-v1 g-bg-gray-light-v5 g-color-gray-light-v1--hover rounded-circle"
                                    href="https://www.facebook.com/cooperativaintegrada" target="_blank">
                                    <i
                                        class="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-facebook"></i>
                                    <i
                                        class="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-facebook"></i>
                                </a>
                            </li>
                            <li class="list-inline-item g-mx-2">
                                <a class="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-gray-light-v1 g-bg-gray-light-v5 g-color-gray-light-v1--hover rounded-circle"
                                    href="https://www.linkedin.com/company/integrada-cooperativa-agroindustri"
                                    target="_blank">
                                    <i
                                        class="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-linkedin"></i>
                                    <i
                                        class="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-linkedin"></i>
                                </a>
                            </li>
                            <li class="list-inline-item g-mx-2">
                                <a class="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-gray-light-v1 g-bg-gray-light-v5 g-color-gray-light-v1--hover rounded-circle"
                                    href="https://www.youtube.com/watch?v=0F3yAPS9kXA" target="_blank">
                                    <i
                                        class="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-youtube"></i>
                                    <i
                                        class="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-youtube"></i>
                                </a>
                            </li>
                        </ul>

                    </div>
                </div> -->

            



            <div class="col-lg-8">

                <div class="g-mb-50" style="text-align: justify; font-size: 12pt">
                    <p>
                        A Integrada Cooperativa Agroindustrial foi fundada em Londrina (PR), no dia 6 de dezembro de
                        1995, por um grupo de agricultores confiantes no sistema cooperativista.
                    </p>
                    <p>
                        Com mais de duas décadas de existência, 15 regionais e 65 unidades de recebimento, distribuídas
                        em notórias áreas produtoras dos estados do Paraná e São Paulo, a Integrada se tornou uma das
                        principais cooperativas do Brasil.
                    </p>
                    <p>
                        Presente em 51 municípios, a Integrada conta com mais de 12 mil cooperados e mais de 2.000
                        colaboradores, dedicados a impulsionar a força do agronegócio no país, com um faturamento de 8,2
                        bilhões de reais, em 2022.
                    </p>
                    <p>
                        Atuante na venda de insumos, assistência técnica e recebimento da produção agrícola, a Integrada
                        atua nos mercados de soja, milho, trigo, café, laranja, entre outras culturas. A maior parte de
                        seu faturamento vem da comercialização de grãos.
                    </p>


                </div>


            </div>



            <!-- <div class="col-lg-12" style="text-align: justify; margin-top: -50px; font-size: 12pt">
                <p>
                    Com foco em criar valor para seus cooperados, a Integrada também tem crescido no segmento da
                    agroindustrialização. A cooperativa possui três modernas unidades industriais, localizadas nas
                    cidades de Andirá (PR), Londrina (PR) e Uraí (PR), dedicadas respectivamente à fabricação de
                    derivados de milho, rações para diversos animais e alimentos pet, além de óleos essenciais e suco
                    concentrado de laranja. Com a verticalização da cadeira produtiva, parte da produção dos cooperados
                    da Integrada é utilizada em suas indústrias, garantindo aos clientes a qualidade presente no campo.
                </p>

                <p>
                    Em sua estrutura, a cooperativa também conta com uma unidade de difusão tecnológica, três unidades
                    de beneficiamento de sementes e cinco lojas de máquinas e equipamentos.
                </p>

                <p>
                    <i class="icon-arrow-right-circle g-color-primary g-mt-5 g-mr-10"></i>
                    <b style="color: #72c02c!important">Inovação</b>
                </p>
                <p>
                    Com os pés no presente e olhar atento ao futuro, a Integrada tem investido fortemente em inovação.
                    Os esforços estão voltados a impulsionar a agricultura de precisão, a agricultura digital, o
                    desenvolvimento de sistemas e aplicativos, entre outras iniciativas, como a participação em eventos
                    e o apoio a startups ligadas ao agro.
                </p>

                <p>
                    <i class="icon-arrow-right-circle g-color-primary g-mt-5 g-mr-10"></i>
                    <b style="color: #72c02c!important">Equilíbrio entre o setor econômico e socioambiental</b>
                </p>
                <p>
                    A melhoria da qualidade de vida das pessoas, a promoção social e o trabalho de responsabilidade
                    socioambiental são considerados prioritários no cotidiano das cooperativas.
                </p>
                <p>
                    Neste contexto, a Integrada difunde, em toda a sua área de atuação, os princípios cooperativistas,
                    investe em diversos programas voltados à preservação ambiental, ao manejo sustentável da atividade
                    agrícola e a ações sociais.
                </p>
                <p><span><b>•1,3 milhão</b></span> de mudas plantadas.</p>
                <p><span><b>•3.714</b></span> hectares de área recuperada.</p>
                <p><span><b>•Mais de 222</b></span> hectares de floresta própria.</p>
                <p><span><b>•Mais de 700 mil</b></span> peixes juvenis soltos nos principais rios do Paraná.</p>
                <p><span><b>•93</b></span> nascentes recuperadas.</p>

                <p>
                    <i class="icon-arrow-right-circle g-color-primary g-mt-5 g-mr-10"></i>
                    <b style="color: #72c02c!important">A força da união</b>
                </p>
                <p>
                    Todas essas conquistas, alcançadas pela Integrada ao longo de 27 anos, são fruto da confiança e
                    dedicação dos cooperados e colaboradores. A força dessa união, somada a parcerias de respeito e ao
                    trabalho sério, comprometido e de qualidade, garantem o crescimento sustentável da cooperativa e de
                    seus associados, geram valor para o agronegócio e desenvolvimento para o Brasil.
                </p>

            </div> -->
        </figure>
    </section>














    <footer class="g-bg-gray-dark-v1 g-color-white-opacity-0_8 g-py-20">
        <div class="container">
            <div class="row">
                <div class="col-md-8 text-center text-md-left g-mb-15 g-mb-0--md">
                    <div class="d-lg-flex">
                        <small class="d-block g-font-size-default g-mr-10 g-mb-10 g-mb-0--md">2024 © Todos direitos
                            reservados.</small>

                    </div>
                </div>
            </div>
        </div>
    </footer>

</main></template>

<script>
</script>