import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import('./components/Dashboard.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard' }],
        },
    },
    {
        path: '/favorites/dashboardanalytics',
        name: 'dashboardanalytics',
        exact: true,
        component: () => import('./components/DashboardAnalytics.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard Analytics' }],
        },
    },
    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('./components/FormLayoutDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Form Layout' }],
        },
    },
    {
        path: '/invalidstate',
        name: 'invalidstate',
        component: () => import('./components/InvalidStateDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Invalid State' }],
        },
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('./components/InputDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Input' }],
        },
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('./components/FloatLabelDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
        },
    },
    {
        path: '/button',
        name: 'button',
        component: () => import('./components/ButtonDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Button' }],
        },
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('./components/TableDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Table' }],
        },
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('./components/ListDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'List' }],
        },
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Timeline' }],
        },
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('./components/TreeDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Tree' }],
        },
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('./components/PanelsDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Panel' }],
        },
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('./components/OverlaysDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Overlay' }],
        },
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('./components/MediaDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Media' }],
        },
    },
    {
        path: '/menus',
        component: () => import('./components/MenusDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menus/seat',
                component: () => import('./components/menu/SeatDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menus/payment',
                component: () => import('./components/menu/PaymentDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menus/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('./components/MessagesDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Messages' }],
        },
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('./components/FileDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'File' }],
        },
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('./components/ChartsDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Charts' }],
        },
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('./components/MiscDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Misc' }],
        },
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('./utilities/Icons.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Icons' }],
        },
    },
    {
        path: '/widgets',
        name: 'widgets',
        component: () => import('./utilities/Widgets.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Widgets' }],
        },
    },
    {
        path: '/grid',
        name: 'grid',
        component: () => import('./utilities/GridDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Grid System' }],
        },
    },
    {
        path: '/spacing',
        name: 'spacing',
        component: () => import('./utilities/SpacingDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Spacing' }],
        },
    },
    {
        path: '/elevation',
        name: 'elevation',
        component: () => import('./utilities/ElevationDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Elevation' }],
        },
    },
    {
        path: '/typography',
        name: 'typography',
        component: () => import('./utilities/Typography.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Typography' }],
        },
    },
    {
        path: '/display',
        name: 'display',
        component: () => import('./utilities/DisplayDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Display' }],
        },
    },
    {
        path: '/flexbox',
        name: 'flexbox',
        component: () => import('./utilities/FlexBoxDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Flexbox' }],
        },
    },
    {
        path: '/text',
        name: 'text',
        component: () => import('./utilities/TextDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Text' }],
        },
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Crud' }],
        },
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('./pages/CalendarDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Calendar' }],
        },
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('./pages/Invoice.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Invoice' }],
        },
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('./pages/Help.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Help' }],
        },
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }],
        },
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Documentation' }],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
      path: "/validar-assinatura",
      name: "validarAssinatura",
      component: () => import("./pages/ValidarAssinatura.vue"),
    },
    {
        path: '/recuperar-senha',
        name: 'recuperar-senha',
        component: () => import('./pages/RecuperarSenha.vue')
    },
    {
        path: '/novo-cadastro',
        name: 'novo-cadastro',
        component: () => import('./pages/NovoCadastro.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/Landing.vue')
    },
    {
        path: '/contactus',
        name: 'contactus',
        component: () => import('./pages/ContactUs.vue')
    },
    {
        path: '/blocks',
        name: 'blocks',
        component: () => import('./components/BlocksDemo.vue')
    },

    /* FIM COMPONENTES DO TEMPLATE */

	/* MENUS DA INTEGRADA */
	//CONSULTA
	{
		path: '/atendimentos',
		name: 'atendimentos',
		meta: {
			breadcrumb: [{parent:'Dossiê', label:'Atendimento'}]
		},
		component: () => import('./pages/consulta/Atendimentos.vue')
	},
	{
		path: '/credencial',
		name: 'credencial',
		meta: {
			breadcrumb: [{parent:'Consulta', label:'Credencial'}]
		},
		component: () => import('./pages/consulta/Credencial.vue')
	},
    {
		path: '/capital-social',
		name: 'capitalSocial',
		meta: {
			breadcrumb: [{parent:'Finanças', label:'Capital Social'}]
		},
		component: () => import('./pages/consulta/CapitalSocial.vue')
	},


	{
		path: '/dados-pessoais',
		name: 'dadosPessoais',
		meta: {
			breadcrumb: [{parent:'Cooperado', label:'Dados Pessoais'}]
		},
		component: () => import('./pages/consulta/DadosPessoais.vue')
	},
	{
		path: '/dossie-agricola',
		name: 'dossieAgricola',
		meta: {
			breadcrumb: [{parent:'Dossiê', label:'Dossiê Agrícola'}]
		},
		component: () => import('./pages/consulta/DossieAgricola.vue')
	},
	{
		path: '/entrega-producao',
		name: 'entregaProducao',
		meta: {
			breadcrumb: [{parent:'Produção', label:'Entrega de Produção'}]
		},
		component: () => import('./pages/consulta/EntregaProducao.vue')
	},
	{
		path: '/historico-pedido',
		name: 'historicoPedido',
		meta: {
			breadcrumb: [{parent:'Insumos', label:'Histórico de Pedido'}]
		},
		component: () => import('./pages/consulta/HistoricoPedido.vue')
	},
	{
		path: '/historico-preco',
		name: 'historicoPreco',
		meta: {
			breadcrumb: [{parent:'Preços', label:'Histórico de Preço'}]
		},
		component: () => import('./pages/consulta/HistoricoPrecos.vue')
	},
	{
		path: '/historico-venda',
		name: 'historicoVenda',
		meta: {
			breadcrumb: [{parent:'Produção', label:'Histórico de Venda'}]
		},
		component: () => import('./pages/consulta/HistoricoVenda.vue')
	},
	{
		path: '/historico-acesso',
		name: 'historicoAcesso',
		meta: {
			breadcrumb: [{parent:'Outros', label:'Histórico de Acesso'}]
		},
		component: () => import('./pages/consulta/HistoricoAcesso.vue')
	},
	{
		path: '/imoveis-cadastrados',
		name: 'imoveisCadastrados',
		meta: {
			breadcrumb: [{parent:'Cooperado', label:'Imóveis Cadastrados'}]
		},
		component: () => import('./pages/consulta/ImoveisCadastrados.vue')
	},
	{
		path: '/plano-saude',
		name: 'planoSaude',
		meta: {
			breadcrumb: [{parent:'Cooperado', label:'Plano de Saúde'}]
		},
		component: () => import('./pages/consulta/PlanoSaude.vue')
	},

	{
		path: '/informe-rendimentos',
		name: 'informeRendimentos',
		meta: {
			breadcrumb: [{parent:'Finanças', label:'Informe de Rendimento'}]
		},
		component: () => import('./pages/consulta/InformeRendimentos.vue')
	},
	{
		path: '/precos-integrada',
		name: 'precosIntegrada',
		meta: {
			breadcrumb: [{parent:'Preços', label:'Preço da Integrada'}]
		},
		component: () => import('./pages/consulta/PrecosIntegrada.vue')
	},
	{
		path: '/sobras',
		name: 'sobras',
		meta: {
			breadcrumb: [{parent:'Finanças', label:'Sobra'}]
		},
		component: () => import('./pages/consulta/ConsultaSobras.vue')
	},
    {
		path: '/historico-chuvas',
		name: 'historicoChuva',
		meta: {
			breadcrumb: [{parent:'Dossiê', label:'Estação Meteorológica'}]
		},
		component: () => import('./pages/consulta/HistoricoChuva.vue')
	},
	//FIM CONSULTA

	//INÍCIO EXTRATO
	{
		path: '/contas-pagar',
		name: 'contasPagar',
		meta: {
			breadcrumb: [{parent:'Finanças', label:'Contas a Pagar'}]
		},
		component: () => import('./pages/extrato/ContasPagar.vue')
	},
	{
		path: '/contas-receber',
		name: 'contasReceber',
		meta: {
			breadcrumb: [{parent:'Finanças', label:'Contas a Receber'}]
		},
		component: () => import('./pages/extrato/ContasReceber.vue')
	},
	{
		path: '/contratos',
		name: 'contratos',
		meta: {
			breadcrumb: [{parent:'Transações Comerciais', label:'Contrato'}]
		},
		component: () => import('./pages/extrato/Contratos.vue')
	},
	{
		path: '/extrato-resumido',
		name: 'extratoResumido',
		meta: {
			breadcrumb: [{parent:'Outros', label:'Extrato Resumido'}]
		},
		component: () => import('./pages/extrato/ExtratoResumido.vue')
	},
	{
		path: '/pedidos',
		name: 'pedidos',
		meta: {
			breadcrumb: [{parent:'Insumos', label:'Pedido'}]
		},
		component: () => import('./pages/extrato/Pedidos.vue')
	},
  {
		path: '/pedidos/assinatura-gerente/:numeroDocumento',
		name: 'assinaturaPedido',
		meta: {
			breadcrumb: [{parent:'Pedido', label:'Assinatura Gerente'}]
		},
		component: () => import('./pages/assinaturaEletronica/assinaturaPedido.vue')
	},
    {
		path: '/produtos-disponiveis',
		name: 'produtosDisponiveis',
		meta: {
			breadcrumb: [{parent:'Insumos', label:'Produtos Disponíveis'}]
		},
		component: () => import('./pages/extrato/ProdutosDisponiveis.vue')
	},
	{
		path: '/produtos-armazenados',
		name: 'produtosArmazenados',
		meta: {
			breadcrumb: [{parent:'Produção', label:'Produtos a Fixar'}]
		},
		component: () => import('./pages/extrato/ProdutosArmazenados.vue')
	},
	{
		path: '/taxa-armazenagem',
		name: 'taxaArmazenagem',
		meta: {
			breadcrumb: [{parent:'Produção', label:'Taxa de Armazenagem'}]
		},
		component: () => import('./pages/extrato/TaxaArmazenagem.vue')
	},
	//Fim extrato
	//Estabelecimento
	{
		path: '/estabelecimentos',
		name: 'estabelecimentos',
		meta: {
			breadcrumb: [{parent:'Relação com o Cooperado', label:'Nossas Unidades'}]
		},
		component: () => import('./pages/consulta/Estabelecimentos.vue')
	},
	//Fim Estabelecimento
	//Classificados
	{
		path: '/classificados',
		name: 'classificados',
		meta: {
			breadcrumb: [{parent:'Classificados', label:'Classificado'}]
		},
		component: () => import('./pages/consulta/Classificados.vue')
	},
	{
		path: '/classificado/:classificadoId',
		name: 'visualizarClassificado',
		meta: {
			breadcrumb: [{parent:'Classificados', label:'Classificado'}]
		},
		component: () => import('./pages/consulta/Classificados.vue')
	},
	{
		path: '/classificado/editar/:classificadoId',
		name: 'editarClassificado',
		meta: {
			breadcrumb: [{parent:'Classificados', label:'Classificado'}]
		},
		component: () => import('./pages/consulta/Classificados.vue')
	},
	//Fim Classificados
	
    //Download
	{
		path: '/notas-fiscais',
		name: 'notasFiscais',
		meta: {
			breadcrumb: [{parent:'Transações Comerciais', label:'Nota Fiscal'}]
		},
		component: () => import('./pages/download/NotasFiscais.vue')
	},
	{
		path: '/estatuto-social',
		name: 'estatutoSocial',
		meta: {
			breadcrumb: [{parent:'Relação com o Cooperado', label:'Estatuto Social'}]
		},
		component: () => import('./pages/download/EstatutoSocial.vue')
	},
    //Fim Download
    {
		path: '/chatbot',
		name: 'chatbot',
		meta: {
			breadcrumb: [{parent:'Download', label:'chatbot'}]
		},
		component: () => import('./components/widgets/chatbot/IrisApp.vue')
	},
    // Configurações
	{
		path: '/manter-favoritos',
		name: 'manterFavoritos',
		meta: {
			breadcrumb: [{parent:'Outros', label:'Favoritos'}]
		},
		component: () => import('./pages/configuracoes/ManterFavoritos.vue')
	},
    
    // Fim Configurações

    // Mensagem
	{
		path: '/mensagens',
		name: 'mensagens',
		meta: {
			breadcrumb: [{parent:'Mensagens'}]
		},
		component: () => import('./pages/Mensagem.vue')
	},
	{
		path: '/mensagem/:mensagemId',
		name: 'visualizarMensagem',
		meta: {
			breadcrumb: [{parent:'Mensagem', label:'Visualizar'}]
		},
		component: () => import('./pages/Mensagem.vue')
	},
    // Fim Mensagem

    //Cadastro
	{
		path: '/cooperados',
		name: 'cooperados',
		meta: {
			breadcrumb: [{parent:'Cadastro', label:'Cooperado'}]
		},
		component: () => import('./pages/cadastro/Cooperado.vue')
	},
    {
		path: '/cooperados/:matricula',
		name: 'alterarCooperado',
		meta: {
			breadcrumb: [{parent:'Cooperado', label:'Cooperado'}]
		},
		component: () => import('./pages/cadastro/Cooperado.vue')
	},
    {
		path: '/usuarios',
		name: 'usuarios',
		meta: {
			breadcrumb: [{parent:'Cadastro', label:'Usuário'}]
		},
		component: () => import('./pages/cadastro/Usuario.vue')
	},
    {
		path: '/usuarios/cadastro',
		name: 'cadastrarUsuario',
		meta: {
			breadcrumb: [{parent:'Usuário', label:'Usuário'}]
		},
		component: () => import('./pages/cadastro/Usuario.vue')
	},
    {
		path: '/usuarios/:matricula',
		name: 'alterarUsuario',
		meta: {
			breadcrumb: [{parent:'Usuário', label:'Usuário'}]
		},
		component: () => import('./pages/cadastro/Usuario.vue')
	},
    {
		path: '/funcionalidades',
		name: 'funcionalidades',
		meta: {
			breadcrumb: [{parent:'Cadastro', label:'Funcionalidade'}]
		},
		component: () => import('./pages/cadastro/Funcionalidade.vue')
	},
    {
		path: '/imagem-fundo',
		name: 'imagemFundo',
		meta: {
			breadcrumb: [{parent:'Cadastro', label:'Imagem de Fundo'}]
		},
		component: () => import('./pages/configuracoes/ImagemFundo.vue')
	},
    {
        path: '/informacoes-exclusao-conta',
        name: 'InformacoesExclusaoConta',
        component: () => import('./pages/InformacoesExclusaoConta.vue')
    },
    {
		path: '/adm-duvidas-frequentes',
		name: 'duvidasFrequentes',
		meta: {
			breadcrumb: [{parent:'Cadastro', label:'Dúvidas Frequentes'}]
		},
		component: () => import('./pages/cadastro/AdministrarDuvidasFrequentes.vue')
	},
    {
	 	path: '/adm-duvidas-frequentes/cadastro',
	 	name: 'cadastrarDuvidasFrequentes',
	 	meta: {
	 		breadcrumb: [{parent:'Dúvidas Frequentes', label:'Cadastrar Dúvida Frequente'}]
	    },
	 	component: () => import('./pages/cadastro/AdministrarDuvidasFrequentes.vue')
	},
    {
	 	path: '/adm-duvidas-frequentes/:id',
	 	name: 'alterarDuvidasFrequentes',
	 	meta: {
	 		breadcrumb: [{parent:'Dúvidas Frequentes', label:'Editar Dúvida Frequente'}]
	 	},
	 	component: () => import('./pages/cadastro/AdministrarDuvidasFrequentes.vue')
	},

    {
		path: '/assinatura-eletronica/assinatura-gerente/:idAssinatura',
		name: 'assinaturaGerente',
		meta: {
			breadcrumb: [{parent:'Assinatura Eletrônica', label:'Assinatura do Gerente'}]
		},
		component: () => import('./pages/assinaturaEletronica/assinaturaGerente.vue')
	},

	//Fim Cadastro

	/* FIM MENUS DA INTEGRADA*/

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;