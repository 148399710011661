import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/usuarios`;

class UsuarioService {
    getUsuario(matricula) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/${matricula}`);
    }

    getUsuarios(params) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}`, {params});
    }

    ativar(matricula) {
        return axios.put(`${ API_BASE_URL}${RESOURCE_V1}/${matricula}/ativo`);
    }

    inativar(matricula) {
        return axios.put(`${ API_BASE_URL}${RESOURCE_V1}/${matricula}/inativo`);
    }

    alterar(usuario) {
        return axios.put(`${API_BASE_URL}${RESOURCE_V1}`, usuario);
    }

    buscarMatriculasDisponiveis(matricula) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/${matricula}/disponivel`);
    }

    buscarPorLoginLdap(usuario) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/login/${usuario}`);
    }
}

export default new UsuarioService();
