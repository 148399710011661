<template>
    <Dialog v-model:visible="visivelProp"
            :style="{width: '80%'}"
            :modal="true"
            @hide="$emit('fechar')">

        <template #header>
            <div class="flex align-items-center">
                <i class="pi pi-unlock mr-1" style="font-size: 1.4rem"></i>
                <h3 style="margin:0px 5px;">Nossas Unidades</h3>
            </div>
        </template>
        <DataTable :value="estabelecimentos" 
                class="p-datatable-gridlines" 
                dataKey="id" 
                filterDisplay="menu" 
                :rowHover="true" 
                :loading="loading" 
                responsiveLayout="scroll" >
            <template #empty>
                Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando dados. Por favor, aguarde!
            </template>
            <Column field="codigo" header="Código" style="width: 5%; " />
            <Column field="nome" header="Nome" style="width: 20%; " />
            <Column field="telefone" header="Telefone" style="width: 5%; " />
            <Column field="endereco" header="Endereço" style="width: 20%; " />
            <Column field="cidade" header="Cidade" style="width: 10%; " />
        </DataTable>
    </Dialog>
</template>

<script>
    import EstabelecimentoService from '../../service/EstabelecimentoService';

    export default {
        emits: ['fechar'],
        props: {
            visivelProp: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                estabelecimentos: [],
                filters1: null,
                loading: false,
            }
        },

        created() {
            this.buscarEstabelecimentos();
        },

        methods: {
            buscarEstabelecimentos() {
                let params = {
                    sort: 'codigo,ASC'
                };
                this.carregarLoading();
                EstabelecimentoService.getEstabelecimentos()
                    .then(({ data }) => {
                    if (data) {
                        this.estabelecimentos = data;
                        this.carregarLoading();
                    }
                });
            },

            carregarLoading() {
                this.loading = !this.loading;
            },

            abrirToast(tipo, titulo, descricao, tempo) {
                this.$toast.add({
                    severity: tipo, 
                    summary: titulo, 
                    detail: descricao, 
                    life: tempo
                });
            },

            fecharModal() {
                return this.$emit('fechar');
            },
        }
    }
</script>
