<template>
    <Login v-if="$route.path === '/login'" />
    <RecuperarSenha v-else-if="$route.path === '/recuperar-senha'" />
    <NovoCadastro v-else-if="$route.path === '/novo-cadastro'" />
    <ValidarAssinatura v-else-if="$route.path === '/validar-assinatura'" />
    <ValidarAssinatura v-else-if="$route.path === '/validar-assinatura/'" />
    <Error v-else-if="$route.path === '/error'" />
    <Access v-else-if="$route.path === '/access'" />
    <NotFound v-else-if="$route.path === '/notfound'" />
    <Landing v-else-if="$route.path === '/landing'" />
    <ContactUs v-else-if="$route.path === '/contactus'" />
    <InformacoesExclusaoConta v-else-if="$route.path === '/informacoes-exclusao-conta'" />
    <IrisApp v-else-if="$route.path === '/chatbot'" />
    <App :topbarTheme="topbarTheme" :layoutMode="layoutMode" :menuTheme="menuTheme" @menu-theme="onMenuTheme"
        @topbar-theme="onTopbarThemeChange" @layout-mode-change="onLayoutChange" v-else />
</template>

<script>
import App from './App.vue';
import Error from './pages/Error';
import Access from './pages/Access';
import Login from './pages/Login';
import ValidarAssinatura from './pages/ValidarAssinatura';
import RecuperarSenha from './pages/RecuperarSenha';
import NotFound from './pages/NotFound';
import Landing from './pages/Landing';
import ContactUs from './pages/ContactUs';
import InformacoesExclusaoConta from './pages/InformacoesExclusaoConta';
import NovoCadastro from './pages/NovoCadastro.vue';
import IrisApp from './components/widgets/chatbot/IrisApp.vue';

export default {
    data() {
        return {
            theme: this.$store.state.auth.user != null ? this.$store.state.auth.user.tema : 'green',
            layoutMode: this.$store.state.auth.user != null ? this.$store.state.auth.user.tema : 'green',
            topbarTheme: this.$store.state.auth.user != null ? this.$store.state.auth.user.corCabecalho : 'green',
            menuTheme: this.$store.state.auth.user != null ? this.$store.state.auth.user.corMenu : 'light',
        };
    },
    methods: {
        onLayoutChange(layout) {
            this.layoutMode = layout;
            this.$appState.layoutMode = layout;
            this.menuTheme = layout;

            const logo = document.getElementById('logo');

            if (logo) {
                logo.src = 'layout/images/logo_portal_branca.png';
            }

            if (layout === 'dark') {
                this.topbarTheme = 'dark';
            }
            else {
                this.topbarTheme = 'green';
            }
        },
        onTopbarThemeChange(theme) {
            this.topbarTheme = theme.name;

            const themeName = theme.name;
            const logo = document.getElementById('logo');

            if (themeName == 'white' || themeName == 'yellow' || themeName == 'amber' || themeName == 'orange' || themeName == 'lime') {
                logo.src = 'layout/images/logo_portal_branca.png';
            }
            else {
                logo.src = 'layout/images/logo_portal_branca.png';
            }
        },
        onMenuTheme(menuTheme) {
            this.menuTheme = menuTheme.name;
        }
    },
    components: {
        "App": App,
        "Error": Error,
        "Access": Access,
        "Login": Login,
        "ValidarAssinatura": ValidarAssinatura,
        "RecuperarSenha": RecuperarSenha,
        "NotFound": NotFound,
        "Landing": Landing,
        "ContactUs": ContactUs,
        "InformacoesExclusaoConta": InformacoesExclusaoConta,
        "NovoCadastro": NovoCadastro,
        "IrisApp": IrisApp,
    }
};
</script>

<style scoped>
/* @import url('https://www.integrada.coop.br/javax.faces.resource/theme.css.xhtml?ln=primefaces-aristo');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/bootstrap/bootstrap.min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/icon-awesome/css/font-awesome.min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/icon-line/css/simple-line-icons.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/icon-line-pro/style.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/icon-hs/style.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/animate.min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/fancybox/jquery.fancybox.min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/slick-carousel/slick/slick.min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/dzsparallaxer/dzsparallaxer.min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/dzsparallaxer/dzsscroller/scroller.min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/dzsparallaxer/advancedscroller/plugin.min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/typedjs/typed.min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/hs-megamenu/src/hs.megamenu.min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/vendor/hamburgers/hamburgers.min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/css/unify-min.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/jssocial/styles/jssocials.css.xhtml');
    @import url('https://www.integrada.coop.br//javax.faces.resource/jssocial/styles/jssocials-theme-flat.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/css/custom.css.xhtml');
    @import url('https://www.integrada.coop.br/javax.faces.resource/jquery/jquery.js.xhtml?ln=primefaces&amp;v=6.1');
    @import url('https://www.integrada.coop.br/javax.faces.resource/jquery/jquery-plugins.js.xhtml?ln=primefaces&amp;v=6.1');
    @import url('https://www.integrada.coop.br/javax.faces.resource/core.js.xhtml?ln=primefaces&amp;v=6.1');
    @import url('https://www.integrada.coop.br/javax.faces.resource/components.css.xhtml?ln=primefaces&amp;v=6.1');
    @import url('https://www.integrada.coop.br/javax.faces.resource/components.js.xhtml?ln=primefaces&amp;v=6.1'); */
</style>
