import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/funcionalidade`;

class FuncionalidadeService {
    listar() {
        return axios.get(`${ API_BASE_URL }${RESOURCE_V1}`);
    }

    carregarFuncionalidades() {
        return axios.get(`${ API_BASE_URL }${RESOURCE_V1}`);
    }

    atualizar(funcionalidades) {
        return axios.put(`${ API_BASE_URL }${RESOURCE_V1}`, funcionalidades);
    }
}

export default new FuncionalidadeService();
