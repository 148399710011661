import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/historico`;

class HistoricoAcessoService {

    getHistorico(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/todos-cooperado/" + matricula);
    }

    salvar(historicoAcesso) {
        return axios.post(`${API_BASE_URL}${RESOURCE_V1}`, historicoAcesso);
    }
}

export default new HistoricoAcessoService();